import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";

const initialState = {
    allReports:[],
    searchText:"",
    // status_start_date:"",
    // status_end_date:"",
    start_date:"",
    end_date:"",
    status:"",
    filteredReports:[],
    total_revenue:"",
    total_customers:"",
    total_trips:"",
    total_drivers:"",
    apiLoading:true

}

export const getAllReports = createAsyncThunk(  "report/getAllReports", async (page, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.get(`/report/reports/?page=${page}`);
    //   console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

// export const getAllReports = createAsyncThunk("report/getAllReports",async(page)=>{
//     const response = await axios({
//         method:"get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/report/reports/?page=${page}`,
//     })

//     return response;
// })

export const getFilteredReports = createAsyncThunk("report/getFilteredReports",async(data)=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/report/reports/?page=${data.page+"&"}${data.searchText?"search=":""}${data.searchText?data.searchText+"&":""}${data.start_date?"start_date=":""}${data.start_date?data.start_date+"&":""}${data.end_date?"end_date=":""}${data.end_date?data.end_date+"&":""}${data.status?"trip_type=":""}${data.status?data.status+"&":""}`,
    })

    return response;
})

export const DownloadReports = createAsyncThunk("report/DownloadReports",async(data)=>{
    const response = await axios({
        method:"get",
        responseType: "blob",
        // contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ,
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/report/download-reports/${data.searchText || data.start_date || data.end_date || data.status ? "?" : ""}${data.searchText?"search=":""}${data.searchText?data.searchText+"&":""}${data.start_date?"start_date=":""}${data.start_date?data.start_date+"&":""}${data.end_date?"end_date=":""}${data.end_date?data.end_date+"&":""}${data.status?"trip_type=":""}${data.status?data.status:""}`,
    })

    return response;
})




const reportSlice = createSlice({
name:"report",
initialState,
reducers:{
    setSearch : (initialState,action)=>{
        initialState.searchText = action.payload
    },
    setStartDate : (initialState,action)=>{
        initialState.start_date = action.payload
    },
    setEndDate:(initialState,action)=>{
        initialState.end_date = action.payload
    },
    setStatus:(initialState,action)=>{
        initialState.status = action.payload
    },
    setAPILoading:(initialState,action)=>{
        initialState.apiLoading = action.payload
      }
},
extraReducers:(builder)=>{
    builder.addCase(getAllReports.fulfilled,(state,action)=>{
        // console.log(action.payload)
        state.allReports = action.payload.results;
        state.total_revenue = action.payload.total_revenue;
        state.total_trips = action.payload.total_trips;
        state.total_customers = action.payload.total_customers
        state.total_drivers = action.payload.total_drivers;
        state.apiLoading = false
    })
    builder.addCase(getFilteredReports.fulfilled,(state,action)=>{
        state.filteredReports = action.payload.data.results
        state.apiLoading = false
    })
}
})


export default reportSlice;

export const reportSliceActions = reportSlice.actions 