import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";

const initialState = {
    allData : {},
    usersData: {},
    driversData: {},
    notificationData:[],
    notDeleteFlag:false,
    loader : true
}



export const getAllDashboardData = createAsyncThunk("dashboard/getAllDashboardData", async (data,{rejectWithValue, fulfillWithValue}) => {
   
    try {
      const response = await axiosInstance.get(`/report/dashboard-count/`);
      // console.log(response)
  
      return fulfillWithValue(response)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

  export const getAllUsersRevenue = createAsyncThunk("dashboard/getAllUsersRevenue", async (date,{rejectWithValue, fulfillWithValue}) => {

    try {
      const response = await axiosInstance.get(`/report/user-revenew/${date?"?start_date=":""}${date?date:""}`);
      // console.log(response)
      return fulfillWithValue(response)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

// export const getAllDashboardData = createAsyncThunk("dashboard/getAllDashboardData",async()=>{
//     const response = await axios({
//         method:"get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/report/dashboard-count/`,
//     })

//     return response;
// })

// export const getAllUsersRevenue = createAsyncThunk("dashboard/getAllUsersRevenue",async(date)=>{
//     const response = await axios({
//         method:"get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/report/user-revenew/${date?"?start_date=":""}${date?date:""}`,
//     })

//     return response;
// })

export const getAllDriversRevenue = createAsyncThunk("dashboard/getAllDriversRevenue",async(date)=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/report/driver-revenew/${date?"?start_date=":""}${date?date:""}`,
    })

    return response;
})


export const getNotificationDashboard = createAsyncThunk("dashboard/getNotificationDashboard",async()=>{
  const response = await axios({
      method:"get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/notification/admin-notification/`,
  })

  return response;
})

export const deleteSingleNotification = createAsyncThunk("dashboard/deleteSingleNotification",async(id)=>{
  const response = await axios({
      method:"delete",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/notification/admin-notification/${id}`,
  })

  return response;
})


// export const getNotificationDashboard = createAsyncThunk("dashboard/getNotificationDashboard", async ({rejectWithValue, fulfillWithValue}) => {
//    console.log("im not api")
//   try {
//     const response = await axiosInstance.get(`/notification/admin-notification/`);
//     console.log(response)

//     return fulfillWithValue(response)
//   } catch (error) {
//     if(error){
//       return rejectWithValue(error.response.data);
//     }
//   }
// });

export const allClearNotification = createAsyncThunk("dashboard/allClearNotification",async()=>{
  const response = await axios({
      method:"get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/notification/admin-notification/?delete=delete`,
  })

  return response;
})


const dashboardSlice = createSlice({
    name:"dashboard",
    initialState,
    reducers:{
      setLoader:(initialState,action)=>{
        initialState.loader = action.payload
      }
    },
    extraReducers:(builder)=>{
        builder.addCase(getAllDashboardData.fulfilled,(state,actions)=>{
            state.allData = actions.payload.data
        })
        builder.addCase(getAllUsersRevenue.fulfilled,(state,actions)=>{
            state.usersData = actions.payload.data
        })
        builder.addCase(getAllDriversRevenue.fulfilled,(state,actions)=>{
            state.driversData = actions.payload.data
        })
        builder.addCase(getNotificationDashboard.fulfilled,(state,actions)=>{
            state.notificationData =actions.payload.data.results
            state.loader = false
        })
        builder.addCase(deleteSingleNotification.fulfilled,(state,actions)=>{
          state.notDeleteFlag = !state.notDeleteFlag
          state.loader = false
        })
        builder.addCase(allClearNotification.fulfilled,(state,actions)=>{
          state.notDeleteFlag = !state.notDeleteFlag
          state.loader = false
        })
       
    }
})


export default dashboardSlice;

export const dashboardActions = dashboardSlice.actions ;
        