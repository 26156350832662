import React from "react";
import ContractRequestsList from "../components/ContractRequests/ContractRequestsList";
const ContractRequests = () => {
  return (
    <div className="m-10 w-[93%]">
      <ContractRequestsList />
    </div>
  );
};

export default ContractRequests;
