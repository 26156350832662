import { contractRequestActions } from "../redux/contractRequestsSlice";
import { contractManagementActions } from "../redux/contractManagementSlice";
import { customerSliceActions } from "../redux/customerSlice";
import { driverActions } from "../redux/driverSlice";
import { userSliceActions } from "../redux/userSlice";
import { reportSliceActions } from "../redux/reportSlice";
import { contractModificationActions } from "../redux/contractModificationSlice";
import { rolesActions } from "../redux/rolesSlice";


let store;
export const clearStore = (_store) => {
  store = _store;
};


export const clearAllContractRequest = ()=>{
    store.dispatch(contractRequestActions.setDate(""))
    store.dispatch(contractRequestActions.setSearch(""))
    store.dispatch(contractRequestActions.setTripType(""))
}

export const clearAllContractManagement = () =>{
    store.dispatch(contractManagementActions.setStatus(""))
    store.dispatch(contractManagementActions.setType(""))
    store.dispatch(contractManagementActions.setDate(""))
    store.dispatch(contractManagementActions.setSearch(""))
    store.dispatch(contractManagementActions.setpaymentLink(""))
}

// export const clearAllDashboard = () =>{
//   store.dispatch(getAllUsersRevenue(""))
//   store.dispatch(getAllDriversRevenue(""))
// }

export const clearAllContractMod = () =>{
  store.dispatch(contractModificationActions.setTripType(""))
  store.dispatch(contractModificationActions.setDate(""))
  store.dispatch(contractModificationActions.setSearch(""))
}


export const clearAllCustomer = () =>{
  store.dispatch(customerSliceActions.setStatus(""))
  store.dispatch(customerSliceActions.setSearch(""))
}

export const clearAllDriver = () =>{
  store.dispatch(driverActions.setStatus(""))
  store.dispatch(driverActions.setSearch(""))
}


export const clearAllUsers = () =>{
  store.dispatch(userSliceActions.setStatus(""))
  store.dispatch(userSliceActions.setSearch(""))
}


export const clearAllRoles = () =>{
  store.dispatch(rolesActions.setStatus(""))
  store.dispatch(rolesActions.setSearch(""))
}

export const clearAllReports = () =>{
  store.dispatch(reportSliceActions.setEndDate(""))
  store.dispatch(reportSliceActions.setStartDate(""))
  store.dispatch(reportSliceActions.setStatus(""))
  store.dispatch(reportSliceActions.setSearch(""))
}