import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../DriverManagement/drivermanagement.scss";
import CardLayout from "../../common/CardLayout";
import { Select } from "antd";
import ModalLayout from "../../common/ModalLayout";
import viewicon from "../../assets/images/viewicon.svg";
import editicon from "../../assets/images/editicon.svg";
import { Link, useNavigate } from "react-router-dom";
import NewUserCredentials from "../../forms/UserMangement/NewUserCredentials";
import { useSelector,useDispatch } from "react-redux";
import { customerSliceActions, editCustomer, getAllCustomers, getAllFilteredCustomers, getAllOrdersofSelectedCustomer, getSelectedCustomer, getselectedSingleOrder,getAllOrdersofSelectedCustomerFiltered } from "../../redux/customerSlice";
import {Radio,DatePicker,message} from "antd";
import axios from "axios";
import CustomerOrder from "./CustomerOrder";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'; 
import { getNotificationDashboard } from "../../redux/dashboardSlice";
import { clearAllContractManagement, clearAllContractMod, clearAllContractRequest, clearAllDriver, clearAllReports, clearAllRoles, clearAllUsers } from "../../common/clearfunction";
const CustomerDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerState = useSelector(state=>state.customer)
  const authState = useSelector(state=>state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectEditModal,setSelectEditModal] = useState(false)
  const [customerStatus,setCustomerStatus] = useState("")
  const [currentRecord,setCurrentrecord] = useState({})
  const [selectOrdersModal,setSelectOrdersModal] = useState(false)
  const [allOrdersofSelectedList,setAllOrdersofSelectedList] = useState([])
  const [currentOrderId,setCurrentOrderId] = useState("")
  const [editAcess,setEditAcess] = useState(false)
   const [messageApi, contextHolder] = message.useMessage();
   const [totalCount,setTotalCount] = useState(10)
   const [pageno,setPageNo] = useState(1)
   const [orderOptionVal,setOrderOptionVal] = useState("")
   const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );
  //  const [loader,setLoader] = useState(true)
  const showModal = () => {
    setIsModalOpen(true);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(()=>{
    // console.log(authState.acessMenus)
    for(let i =0 ; i <authState.acessMenus.length;i++){
  
      if(authState.acessMenus[i].menu == "customer"){
      //  console.log(authState.acessMenus[i].menu == "contract")
      //  console.log(Object.keys(authState.acessMenus[i]?.actions))
     for(let j = 0; j < Object.keys(authState.acessMenus[i]?.actions).length ; j++){
         if(authState.acessMenus[i].actions.change){
          // console.log(true)
         setEditAcess(true)
         }
     }
    
      }
    }
  },[])


  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])
    const success = (data) => {
    messageApi.open({
      type: "success",
      content: data,
    });
  };

  const columns = [
    {
      title: "Customer ID",
      dataIndex: "id",
      key: "id",
      render: (id, record) => (
        <p style={{ fontSize: "12px" }}>{id}</p>
      ),
    },
    {
      title: "Customer name",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name, record) => (
        <p style={{ fontSize: "12px" }}>{`${first_name} ${record.last_name}`}</p>
      ),
    },
    {
      title: "Contact number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (mobile_number, record) => (
        <p style={{ fontSize: "12px" }}>{mobile_number}</p>
      ),
    },
    {
      title: "Whatsapp number",
      key: "watsap_number",
      dataIndex: "watsap_number",
      render: (watsap_number, record) => (
        <p style={{ fontSize: "12px" }}>{watsap_number}</p>
      ),
    },
    {
      title: "Email ID",
      key: "email",
      dataIndex: "email",
      render: (email, record) => (
        <p style={{ fontSize: "12px" }}>{email}</p>
      ),
    },
    {
      title: "Orders",
      key: "Orders",
      dataIndex: "Orders",
      render: (contractcopy, record) => (
        <p onClick={()=>{
          setCurrentOrderId(record.id)
          dispatch(getAllOrdersofSelectedCustomer(record.id))
          setOrderOptionVal("")
          setSelectOrdersModal(true)
          
       
       
     
        }} style={{ fontSize: "12px" }} className="text-orange-500 cursor-pointer">View</p>
      ),
    },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (_active, record) => {
        return (
          <span
            className={`flex text-sm font-bold leading-4 ${
              _active == true ? "text-green-700" : "text-red-700"
            }`}
          >
            { _active == true ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="flex items-center">
            <img
              src={viewicon}
              alt="View"
              className="mx-2 cursor-pointer"
              title="View"
              onClick={() => {
                dispatch(getSelectedCustomer(record.id))
                dispatch(getAllOrdersofSelectedCustomer(record.id)).then((response)=>{
                  // console.log(response)
                  dispatch(getAllOrdersofSelectedCustomer(record.id)).then(res=>console.log(res))
                  dispatch(customerSliceActions.setOrderType(""))
                  dispatch(customerSliceActions.setDate(""))
                  navigate(`customer-details/${record.id}`)
                })
              }}
            />
            <img
              src={editicon}
              alt="Edit"
              className="mx-2 cursor-pointer"
              title="Edit"
              onClick={()=>{
            if(editAcess){
              setCurrentrecord(record)
              setCustomerStatus(record.is_active)
              setSelectEditModal(true)
            }

              }}
            />
          </div>
        );
      }, 
    },
  ];
 
  const handleChange = (value) => {};

useEffect(()=>{
  dispatch(customerSliceActions.setAPILoading(true))
  clearAllContractRequest()
  clearAllContractManagement()
  clearAllContractMod()
  clearAllDriver()
  clearAllUsers()
  clearAllRoles()
  clearAllReports()
  dispatch(getNotificationDashboard())
  // if(customerState.searchText == "" && customerState.status == "")
  dispatch(getAllCustomers(pageno)).then(res=>(customerState.searchText == "" && customerState.status == "")&&setTotalCount(res.payload.count))
  // setTimeout(()=>setLoader(false),800)
},[selectEditModal,pageno,customerState.editCustomerStatus])

useEffect(()=>{
 const data= {
    searchText : customerState.searchText,
    status: customerState.status,
    page : pageno
  }
  // setTimeout(()=>setLoader(false),800)
  dispatch(getAllFilteredCustomers(data)).then(res=>setTotalCount(res.payload.data.count))
  // setTimeout(()=>setLoader(false),800)
},[customerState.searchText,customerState.status,pageno,customerState.editCustomerStatus])

useEffect(()=>{
  dispatch(customerSliceActions.setAPILoading(true))
  dispatch(customerSliceActions.setDate(""))
    dispatch(getAllOrdersofSelectedCustomer(currentOrderId))

  
},[selectOrdersModal])

useEffect(()=>{
  dispatch(customerSliceActions.setAPILoading(true))
  const data = {
    id:currentOrderId,
    orderDate:customerState.orderDate,
    orderTrip_Type:customerState.orderTrip_Type,
 
  }
  if(selectOrdersModal){
  dispatch(getAllOrdersofSelectedCustomerFiltered(data))

  }
},[customerState.orderDate,customerState.orderTrip_Type])
useEffect(()=>{
  dispatch(customerSliceActions.setOrderType(orderOptionVal))
},[orderOptionVal])
const mapdata = customerState.searchText != "" || customerState.status != "" ? customerState.filteredCustomersList : customerState.allCustomers
const mapDataOrders = customerState.orderTrip_Type != "" || customerState.orderDate != "" ? customerState.filteredCustomerOrders : customerState.allCustomerOrdersList

  return (
    <CardLayout>
            {contextHolder}
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
        <div className="col-span-1">
          <p className="font-bold text-lg leading-6 text-header-title my-2">
            Customer Management
          </p>
        </div>
        <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3">
          {/* <div className="my-2 flex items-center justify-between md:w-full w-1/2"> */}
          <Select
            allowClear
            className="w-[200px]  my-1 font-bold border-none bg-[#f265220d] rounded-md"
            defaultValue="Status"
            onChange={(val)=>{
              // console.log(val)
              // setLoader(true)
              setPageNo(1)
              dispatch(customerSliceActions.setAPILoading(true))
              dispatch(customerSliceActions.setStatus(val))
            }}
            bordered={false}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "true",
                label: "Active",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "false",
                label: "Inactive",
                className: "w-full py-[.5rem] text-xs font-bold",
              },
            ]}
          />

          {/* </div> */}
        </div>
        <div className="flex items-center justify-between flex-wrap"></div>
        {/* {console.log(customerState.allCustomers)} */}
      </div>
       {customerState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:   <Table
         rowKey={record => record.id}
        columns={columns}
        dataSource={mapdata}
        scroll={{ x: 900 }}
        className="font-sm"
        pagination={{  pageSize:10 ,total:totalCount ,onChange:(page,size)=>{setPageNo(page)},showSizeChanger:false ,current:pageno}}
      />}
   
      <ModalLayout
        title="Add User Credentials"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >


        {/* <DriverOnBoarding
          
        /> */}
        <NewUserCredentials
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </ModalLayout>


      <ModalLayout
 width={500}
 isModalOpen={selectEditModal}
 setIsModalOpen={setSelectEditModal}
 className="flex justify-center items-center"
 style={{marginBottom:"100px"}}
>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100 flex-col">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
                {currentRecord.id} {currentRecord.first_name} {currentRecord.last_name}
                </p>
             {/* {console.log(currentRecord)} */}
               
              </div>

          
             
            </div>
            <div className="flex  flex flex-col items-center">
            <p className="text-orange-500 text-lg font-bold pb-4">Customer status</p>
            <div className="flex justify-between">
              {/* {console.log(currentRecord)} */}
              {/* {setCustomerStatus(currentRecord.is_active)} */}
            <Radio.Group onChange={(e)=>setCustomerStatus(e.target.value)} value={customerStatus} className="mr-8">
      <Radio className="text-xl  px-2" value={true} >Active</Radio>
      <Radio className="text-xl px-2" value={false} >Inactive</Radio>
     
    </Radio.Group>
            </div>

              </div>
              <div className="flex justify-center">
                  
                  <button onClick={()=>{
                    const data = {
                      id: currentRecord.id,
                      status: customerStatus
                    }
                    dispatch(editCustomer(data)).then(()=>{setSelectEditModal(false)
                    success("Customer updated successfully")
                    })
                  }} className=" w-[120px] h-[28px] lg:ml-5 my-1 bg-primary text-white   rounded-[8px] leading-4 font-bold text-sm px-5 mt-3">Submit</button>

              
           
              </div>
</div>
</ModalLayout>


<ModalLayout
 width={900}
 isModalOpen={selectOrdersModal}
 setIsModalOpen={setSelectOrdersModal}
 className="flex justify-center items-center"
 style={{marginBottom:"100px"}}
>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100 flex-col">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
              Orders
                </p>
         
          
              </div>

          
             
            </div>

            <div className=" bg-white rounded-3xl mb-8">
        <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
        <div className="col-span-1">
          <p className="font-bold text-lg leading-6 text-header-title my-2">
          Orders
          </p>
        </div>
        <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3">
          {/* <div className="my-2 flex items-center justify-between md:w-full w-1/2"> */}
          <Select
            allowClear
            className="w-[200px]  my-1 font-bold border-none bg-[#f265220d] rounded-md"
            defaultValue="Type"
            value={orderOptionVal}
            onChange={(val)=>setOrderOptionVal(val)}
            bordered={false}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Day%20Trip",
                label: "Day trip",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Contract%20Trip",
                label: "Contract trip",
                className: "w-full py-[.5rem] text-xs font-bold",
              },
            ]}
          />
            <DatePicker  format="DD/MM/YYYY" onChange={(str,val)=>{
                 let newdate = val.split("/").reverse().join("-");
              dispatch(customerSliceActions.setDate(newdate))}} />

          {/* </div> */}
        </div>
        <div className="flex items-center justify-between flex-wrap"></div>
        {/* {console.log(customerState.allCustomers)} */}
      </div>
          {customerState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:mapDataOrders.map((trip, index) => {
            return (
              <CustomerOrder
              arrow= {false}
                _id={trip.id}
                key={trip.id}
                title={trip.trip_type}
                id={trip.trip_id}
                startdate={trip.start_date}
                starttime={trip.start_time}
                pickuploc1={trip.pickup_point?.adress}
                // pickuploc2={trip.pickup_point.}
                dropoffloc1={trip.drop_of_point?.adress}
                // dropoffloc2={trip.dropoffLocation2}
                payment={trip.trip_payment?.status}
              />
            );
          })}
        </div>
          
            
</div>
</ModalLayout>

    </CardLayout>
  );
};

export default CustomerDetails;
