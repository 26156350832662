import React from "react";
import arrowRight from "../../assets/images/chevron-right.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { getselectedSingleOrder } from "../../redux/customerSlice";

const CustomerOrder = ({
  _id,
  title,
  id,
  startdate,
  starttime,
  pickuploc1,
  pickuploc2,
  dropoffloc1,
  dropoffloc2,
  payment,
  arrow
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const showOrderDetails = (id) => {
    dispatch(getselectedSingleOrder(id)).then((res)=>{
      navigate(`orderdetails/${id}`);
    })
   
  };
  return (
    <div className="flex justify-between   bg-bgcolor rounded-3xl mb-8 gap-12 p-4 "> 
      <div className=" flex flex-col justify-between text-secondary w-[100%] lg:w-[70%] ">
        <div className="flex justify-between w-full gap-5 ">
          <div className="px-3 	w-[150px]">
            <p className=" text-sm font-bold text-orange-500">{title} </p>
            <p className="text-xs font-medium">Trip Id: {id}</p>
          </div>
          <div className="px-3 	w-[150px]">
            <p className="mb-1 text-sm font-bold ">Starting Date and Time</p>
            <p className="text-xs font-medium">{startdate}</p>
            <p className="text-xs font-medium">{starttime}</p>
          </div>
          <div className="px-3 	w-[150px]">
            <p className="mb-1 text-sm font-bold ">Pickup Location </p>
            <p className="text-xs font-medium">{pickuploc1}</p>
            <p className="text-xs font-medium">{pickuploc2}</p>
          </div>
          <div className="px-3 	w-[150px]">
            <p className="mb-1 text-sm font-bold ">DropOff Location </p>
            <p className="text-xs font-medium">{dropoffloc1}</p>
            <p className="text-xs font-medium">{dropoffloc2}</p>
          </div>
          <div className="px-3 	w-[150px]">
            <p className="mb-1 text-sm font-bold mr-10">Payment Status </p>
            <p className="text-xs font-medium">
              {payment }
            </p>
          </div>

          <div className="flex items-center ml-auto">
         {arrow&&   <img
              className="mr-5 cursor-pointer"
              src={arrowRight}
              alt=""
              onClick={() => {
                showOrderDetails(_id);
              }}
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrder;
