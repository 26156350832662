import React from "react";
import searchicon from "../../assets/images/searchicon.svg";
import notification from "../../assets/images/notification.svg";
import { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { searchTrip,contractRequestActions } from "../../redux/contractRequestsSlice";
import { getNotificationDashboard } from "../../redux/dashboardSlice";
import closeImg from "../../assets/images/close-circle.svg";
import { useEffect } from "react";
import { contractManagementActions } from "../../redux/contractManagementSlice";
// import debounce from "debounce";
import { useSearchDebounce } from "../../common/useSearchDebounce";

const ContractPageHeader = ({ pageTtitle, SubTitle }) => {
  const dispatch = useDispatch();
  const contractState = useSelector(state=>state.contractrequest)
  
  const dashState = useSelector(state=>state.dashboard)
  const [dropdownClick, setdropdownClick] = useState(false);
  // const [searchText, setSearchText] = ;
  // const [searchVal, setSearchVal] = useState("");
  
// console.log(searchVal)
  // const searchDriverApi = () => {
  //   // if (searchVal.length > 0) {
  //   //   dispatch(driverActions.setStatusFlag(false));
  //   // dispatch(contractRequestActions.setFilterData({searchText}))
  //   // .then(()=>{
  //     // const [value] = debounce(searchVal, 2000);
  //     // console.log(searchText)
  //     dispatch(searchTrip({searchText:contractState.searchText,status:contractState.status,trip_Type:contractState.trip_Type}))
  //   // })
  //   // const data = {searchText}
  //   //   dispatch();
 
  //   // }
  // };

  // useEffect(() => {
  //   searchDriverApi();
  // }, [contractState.searchText,contractState.status,contractState.trip_Type]);
  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 mt-3 mb-10 ">
        <div className="col-span-1">
          <div className="flex text-2xl font-bold leading-9 text-header-title tracking-wider">
            {pageTtitle}
          </div>
          {SubTitle && <SubTitle />}
        </div>
        <div className="col-span-1">
          <div className="flex items-center justify-end md:justify-between">
            <div className="relative w-96">
              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <img src={searchicon} alt="" />
              </div>
              <input
                type="text"
                id="input-group-1"
                className="bg-bgcolor border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-14 p-3.5 h-[39px]"
                // onChange={(e) => debounce(dispatch(contractRequestActions.setSearch(e.target.value)),2000)}
                // onChange={(e)=>setSearchVal(e.target.value)}
                onChange={(e)=> setTimeout(()=>{
                  dispatch(contractManagementActions.setPageNumberFlagToggle())
                  dispatch(contractRequestActions.setSearch(e.target.value))},900) }
                placeholder="Search"
              />
            </div>
            <div className="flex flex-col items-end justify-start">
            <button
              type="button"
              className="border font-medium rounded-lg text-sm  text-center inline-flex items-center p-1 ml-6"
            >
              <img
                src={notification}
                alt="Notification Icon"
                className="h-7 w-7"
                onClick={()=>{
                  dispatch(getNotificationDashboard())
                  setdropdownClick(!dropdownClick)
                }}
              />
            </button>
         <div className=" p-0 m-0">
              <div className="notification_wrap">
                
                <div className={`dropdown ${dropdownClick ? "active" : ""} `}>
                <div className="flex justify-between items-center ">
                <p className="p-3 text-orange-500 font-bold text-xl">Notifications</p>
                <p className="font-light text-md cursor-pointer">Clear all</p>
                </div>
<div className="p-3 overflow-y-scroll scrollbar-color-dash">
                {dashState.notificationData.map(not=>(
                   <div className="notify_item pr-12">
                 
                   <div className="notify_info">
                    <div className="flex justify-between items-center">
                    <p className="w-[350px]">
                     {not.message}
                     </p>
                     <img className="w-[22px] cursor-pointer" src={closeImg} alt="" />
                    </div>
                     
                     
                   </div>
                 </div>
                ))}
                 
                 
               
               </div>
                </div>
              </div>
            </div> 
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractPageHeader;
