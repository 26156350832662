// import dispatchStore from './store'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { authActions } from "./authSlice";
// import {injectStore} from './store'
// import store from './store'
// import { useDispatch } from "react-redux";
// import useLogoutHook from "../common/useLogoutHook";

let store;
export const injectStore = (_store) => {
  store = _store;
};

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_DEV_URL,
    withCredentials: false,
  });
  // default header

  // const dispatch = useDispatch()
  axios.defaults.headers.common["Accept"] = "application/json";
  // access token handling
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("authorization");
      // const unique_token = localStorage.getItem("unique_token");
      if (token ) {
        config.headers.Authorization =token;
        // headers: { "Content-Type": "multipart/form-data" ,Authorization: localStorage.getItem("authorization")},
        config.headers["Content-Type"] = "multipart/form-data"
        // config.headers.key = unique_token;
      }
      return config;
    },
    (error) => {
      // console.log("iiiiiiiiiiiiiiiiiiiiiii")
      return Promise.reject(error);
    }
  );


  axiosInstance.interceptors.response.use(
    (response) => {
      // console.log(response)
      return response;
    },async (error) => {
      // console.log(error.response)
      if(error.response.status == 401 && error.response.data.code =="user_inactive"    ){
        store.dispatch(authActions.logout())
  // navigate("/")
      }
      if (error.response.status === 401 && error.response.data.code == "token_not_valid") {
        const original_req = error.config
        original_req._retry = true;
        // console.log(original_req)
        let refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken) {
          try {
            //try refreshing token
            const data = await  axios.post(`https://dev.otaxi.srv1.mtlstaging.com/api/auth/token/refresh/`,{refresh:refreshToken})
            // console.log(data)
  let accessToken = data.data.access;
            if (accessToken) {
  //if request is successful and token exists in response 
              //store it in local storage
              localStorage.setItem("authorization", `Bearer ${accessToken}`);
  //with new token retry original request
              // config.headers["Authorization"]=accessToken;
              // return customRequest(config);
              return axiosInstance(original_req);
            }
          }
          catch (e) {
           store.dispatch(authActions.logout())
            
          }
        
        }
       }
       else{
        return Promise.reject(error);
       }

      
        return error;
       
   
    }
  );

  export default axiosInstance