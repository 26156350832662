import React, { useEffect,useState } from "react";
import PageHeader from "../layout/PageHeader";
import { Select, DatePicker } from "antd";
import ContractRequests from "../components/ContractModifications/ContractRequests";
import { Link, useNavigate } from "react-router-dom";
import { getAllContractModifications } from "../redux/contractModificationSlice";
import { useDispatch,useSelector } from "react-redux";
import ContractModificationHeader from "../components/ContractModifications/ContractModificationHeader";
import { contractModificationActions,getFilteredModifications } from "../redux/contractModificationSlice";
import { Pagination } from 'antd';
import { tConvert } from "../common/timeConverter";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { clearAllContractManagement, clearAllContractRequest, clearAllCustomer, clearAllDriver, clearAllReports, clearAllRoles, clearAllUsers } from "../common/clearfunction";
import { getNotificationDashboard } from "../redux/dashboardSlice";
const ContractModificationRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contractModificationState =  useSelector((state)=>state.contractmodification)
  const [totalCount,setTotalCount] = useState(10)
  const [pageno,setPageNo] = useState(1)
  const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );
  // const [loader,setLoader] = useState(true)
  
  const data = [
    {
      title: "Reschedule",
      tripType: "Day Trip",
      id: "10111",
      customerName: "person1",
      customerNumber: 786768787,
      customerEmail: "person1@gmail.com",
      TotalPrice: "8 OMR",
      cost: "10 OMR",
      creditPoint: "2 OMR",
      date: "01/01/2022",
      time: "10:00 am",
    },
    {
      title: "Reschedule",
      tripType: "Day Trip",
      id: "10112",
      customerName: "person1",
      customerNumber: 786768787,
      customerEmail: "person1@gmail.com",
      TotalPrice: "8 OMR",
      cost: "10 OMR",
      creditPoint: "2 OMR",
      date: "01/01/2022",
      time: "10:00 am",
    },
    {
      title: "Reschedule",
      tripType: "Day Trip",
      id: "10113",
      customerName: "person1",
      customerNumber: 786768787,
      customerEmail: "person1@gmail.com",
      TotalPrice: "8 OMR",
      cost: "10 OMR",
      creditPoint: "2 OMR",
      date: "01/01/2022",
      time: "10:00 am",
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );


  useEffect(()=>{
    // setTimeout(()=>setLoader(false),800)
    dispatch(contractModificationActions.setAPIloading(true))
    clearAllContractRequest()
    clearAllContractManagement()
    clearAllCustomer()
    clearAllDriver()
    clearAllUsers()
    clearAllRoles()
    clearAllReports()
    dispatch(getNotificationDashboard())
    // if(contractModificationState.searchText == "" && contractModificationState.trip_Type == "" && contractModificationState.date == ""){
      dispatch(getAllContractModifications(pageno)).then(res=>(contractModificationState.searchText == "" && contractModificationState.trip_Type == "" && contractModificationState.date == "")&&setTotalCount(res.payload.count))
      // setTimeout(()=>setLoader(false),800)
    // }
    
  },[contractModificationState.acceptFlag,contractModificationState.rejectFlag,pageno,contractModificationState.cancellationReqStatus])


  useEffect(() => {
    dispatch(contractModificationActions.setAPIloading(true))
    const data = {
      searchText: contractModificationState.searchText,
      trip_Type: contractModificationState.trip_Type,
      date: contractModificationState.date,
      page:pageno
    };
    dispatch(getFilteredModifications(data)).then((res)=>{
      setTotalCount(res.payload.data.count)
      // setTimeout(()=>setLoader(true,700))
      // setLoader(true)
      //  setTimeout(()=>setLoader(false),900)
    });
    // setLoader(true)
    
    // setTimeout(()=>setLoader(false),1900)
  }, [
    contractModificationState.searchText,
    contractModificationState.trip_Type,
    contractModificationState.date,
    pageno,
    contractModificationState.acceptFlag,contractModificationState.rejectFlag,contractModificationState.cancellationReqStatus
  ]);

  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])
  const mapdata =
  contractModificationState.searchText != "" ||
  contractModificationState.trip_Type != "" ||
  contractModificationState.date != ""
    ? contractModificationState.allFilteredContractModifications
    : contractModificationState.allContractModifications;


  return (
   
    <div className="m-10 w-[93%]">
       <PageHeader pageTtitle="Contract Modification Requests" onChangeFunc={contractModificationActions.setSearch}/>
      <div className="p-6 bg-white rounded-3xl mb-8">
        <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
          <div className="col-span-1">
            <p className="font-bold text-lg leading-6 text-header-title my-2">
              Modification Requests
            </p>
          </div>
          <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3">
            <Select
              // allowClear
              className="w-[200px]  my-1 font-bold border-none bg-[#f265220d] rounded-lg"
              defaultValue="Type"
              bordered={false}
              onChange={(val)=>  {
                // setLoader(true)
                setPageNo(1)
                dispatch(contractModificationActions.setAPIloading(true))
                dispatch(contractModificationActions.setTripType(val))}}
              options={[
                {
                  value: "",
                  label: "All",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Reschedule",
                  label: "Reschedule",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Cancel",
                  label: "Cancel",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Renew",
                  label: "Renew",
                  className: "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Driver%20Change",
                  label: "Driver Change",
                  className: "w-full py-[.5rem] text-xs  font-bold",
                },
              ]}
            />
            <DatePicker format="DD/MM/YYYY" onChange={(str,val)=>{
              setPageNo(1)
               let newdate = val.split("/").reverse().join("-");
              dispatch(contractModificationActions.setDate(newdate))} }/>
            {/* </div> */}
          </div>
          <div className="flex items-center justify-between flex-wrap"></div>
        </div>
        {contractModificationState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:mapdata.map((trip, index) => {
          return (
            <ContractRequests
              id_={trip.id}
              key={trip.id}
              title={trip.modification_status}
              reason_cancellation={trip.reason_for_cancelation}
              reason_driver_change={trip.reason_for_change_driver}
              end_time ={tConvert(trip.end_time)}
              end_date = {trip.end_date}
              tripType={trip.trip_type}
              id={trip.trip_id}
              customerName={`${trip.user_id.first_name} ${trip.user_id.last_name}`}
              customerNumber={trip.user_id.mobile_number}
              customerEmail={trip.user_id.email}
              TotalPrice={`${trip.total_amount} OMR`}
              cost={`${trip.price} OMR`}
              creditPoint={`${trip.credit_point} OMR`}
              date={trip.start_date}
              time={tConvert(trip.start_time)}
              modification_status={trip.modification_status}
              user_id={trip.user_id.id}
            />
          );
        })}
    
        <Pagination defaultCurrent={contractModificationState.currentPage} total={totalCount} current={pageno} showSizeChanger={false}  onChange={(page,size)=>{
  setPageNo(page)
}}/>

{/* <Pagination defaultCurrent={contractState.currentPage} total={totalCount}  onChange={(page,size)=>{
  setPageNo(page)
}}/>  */}
      </div>
    </div>
  );
};

export default ContractModificationRequests;
