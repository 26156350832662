import React, { useEffect, useState } from "react";
import PageHeader from "../../layout/PageHeader";
import { Select, DatePicker } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ContractRequestDetails from "./ContractRequestsDeatails";
import { useDispatch, useSelector } from "react-redux";
import { getAllContractRequests,searchTrip ,contractRequestActions} from "../../redux/contractRequestsSlice";
import { DownOutlined } from "@ant-design/icons";
import ContractPageHeader from "./ContractPageHeader";
import { Pagination } from 'antd';
import { tConvert } from "../../common/timeConverter";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getNotificationDashboard } from "../../redux/dashboardSlice";
import { clearAllContractManagement, clearAllContractMod, clearAllCustomer, clearAllDriver, clearAllReports, clearAllRoles, clearAllUsers } from "../../common/clearfunction";

const ContractRequestsList = () => {
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contractState = useSelector((state) => state.contractrequest);
  const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );
  const [totalCount,setTotalCount] = useState(10)
  const [pageno,setPageNo] = useState(1)
  // const [loader,setLoader] = useState(true)
  const data = [
    {
      key: 1,
      title: "One Way Trip",
      id: 1011101,
      startingDate: "11/12/2022",
      startingTime: "10:22 am",
      pickupLocation1: "2346 unknown road",
      pickupLocation2: "westnlwire, arizona",
      dropOffLocation1: "2346 unknown road",
      dropoffLocation2: "westnlwire, arizona",
      customerName: "person",
      email: "name@email.com",
    },
    {
      key: 1,
      title: "One Way Trip",
      id: 1011101,
      startingDate: "11/12/2022",
      startingTime: "10:22 am",
      pickupLocation1: "2346 unknown road",
      pickupLocation2: "westnlwire, arizona",
      dropOffLocation1: "2346 unknown road",
      dropoffLocation2: "westnlwire, arizona",
      customerName: "person",
      email: "name@email.com",
    },
    {
      key: 1,
      title: "One Way Trip",
      id: 1011101,
      startingDate: "11/12/2022",
      startingTime: "10:22 am",
      pickupLocation1: "2346 unknown road",
      pickupLocation2: "westnlwire, arizona",
      dropOffLocation1: "2346 unknown road",
      dropoffLocation2: "westnlwire, arizona",
      customerName: "person",
      email: "name@email.com",
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const callFilter = ()=>{
    
  }

  useEffect(()=>{

   const datass = {
      searchText : contractState.searchText,
      trip_Type: contractState.trip_Type,
      date:contractState.date, 
      page:pageno
    }
  //  console.log(datass)

  if(contractState.searchText != "" || contractState.trip_Type != "" || contractState.date != ""){
    dispatch(contractRequestActions.setAPIloading(true))
    dispatch(searchTrip(datass)).then((res)=>{
      console.log(res.payload.data.count)
      setTotalCount(res.payload.data.count)
    
    })
    // setTimeout(()=>setLoader(false),800)

    // return  
  }
//   return()=>{
//     console.log("im clearing")
   
// // dispatch(getAllContractRequests(pageno))
// // tripdata = contractState.allContracts
//   }
   
  },[contractState.searchText,contractState.status,contractState.trip_Type,contractState.date,pageno])
  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])

  useEffect(() => {
    dispatch(contractRequestActions.setAPIloading(true))
    // {console.log(contractState.apiLoading)}
    clearAllContractManagement()
    clearAllContractMod()
    clearAllCustomer()
    clearAllDriver()
    clearAllUsers()
    clearAllRoles()
    clearAllReports()
    dispatch(getNotificationDashboard())
    // if(contractState.searchText == "" && contractState.trip_Type == "" && contractState.date == ""){
      dispatch(getAllContractRequests(pageno)).then((res)=>(contractState.searchText == "" && contractState.trip_Type == "" && contractState.date == "")&&setTotalCount(res.payload.count));
      // setTimeout(()=>setLoader(false),800)
    // }
    
  }, [contractState.currentPage,pageno,contractState.trip_Type,contractState.date]);

  let tripdata = contractState.searchText != "" ||contractState.trip_Type != "" || contractState.date != ""?contractState.filterResults:contractState.allContracts
  return (
    <div className=" w-[100%] ">
     
     <PageHeader pageTtitle="Contract Requests" onChangeFunc={contractRequestActions.setSearch}/>
      <div className=" bg-white rounded-3xl mb-8 pb-4">
        <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
          <div className="col-span-1">
            <p className="font-bold text-xl leading-6 text-header-title my-2 px-5 py-5">
              Contract Requests
            </p>
          </div>
          <div className="col-span-1 flex items-center md:justify-between justify-end  gap-3 border-0 mx-5">
            {/* <Select
              // allowClear
              // suffixIcon={<DownOutlined />}
              className="w-[170px]  my-1 font-bold border-0 select1 bg-[#f265220d] rounded-lg"
              defaultValue="All"
              bordered={false}
              onChange={(val)=>{
                // setStatus(val)
                // dispatch(contractRequestActions.setFilterData(val))
                dispatch(contractRequestActions.setStatus(val))
              }} 
              options={[
                {
                  value: "New",
                  label: "New",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Accepted",
                  label: "Accepted",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Rejected",
                  label: "Rejected",
                  className: "w-full py-[.5rem] text-xs   font-bold",
                },
              ]}
            /> */}
            <Select
              // allowClear
              className="w-[200px]  my-1 font-bold border-none select1 bg-[#f265220d] rounded-lg"
              defaultValue="Type"
              onChange={(val)=>{
                // setLoader(true)
                setPageNo(1)
                dispatch(contractRequestActions.setAPIloading(true))
                dispatch(contractRequestActions.setTripType(val))
              }}
              bordered={false}
              options={[
                {
                  value: "",
                  label: "All",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Day%20Trip",
                  label: "Day trip",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Contract%20Trip",
                  label: "Contract trip",
                  className: "w-full py-[.5rem] text-xs font-bold",
                },
              ]}
            />
            <DatePicker  format="DD/MM/YYYY" onChange={(date,val)=>{ 
              setPageNo(1)
                       let newdate = val.split("/").reverse().join("-");
               dispatch(contractRequestActions.setAPIloading(true))
              dispatch(contractRequestActions.setDate(newdate)) }}/>
            {/* </div> */}
          </div>
          <div className="flex items-center justify-between flex-wrap"></div>
        </div>
       {/* { console.log(mapdata)} */}
       {/* {console.log(contractState.filterResults.length>0 ? "hello" : "jjj")} */}
      {/* {contractState.filterResults.length > 0 ? contractState.filterResults : contractState.allContracts} */}
      {/* {console.log(tripdata)} */}
     
        {contractState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:tripdata.map((trip, index) => {
          return (
            <ContractRequestDetails
              id={trip.id}
              key={trip.trip_id}
              title={trip.trip_type}
              trip_id={trip.trip_id}
              startdate={trip.start_date}
              starttime={tConvert(trip.start_time)}
              pickuploc1={trip.pickup_point?.adress}
              // pickuploc2={trip.pickup_point.adress_type}
              dropoffloc1={trip.trip_type == "Day Trip" ? "" : trip.drop_of_point?.adress}
              // dropoffloc2={trip.drop_of_point.adress_type}
              name={`${trip.user_id?.first_name} ${trip.user_id?.last_name}`}
              email={trip.user_id?.email}
              number={trip.user_id?.mobile_number}
              end_date={trip.end_date}
              end_time={trip.end_time}
            />
          );
        })}

        
<Pagination defaultCurrent={contractState.currentPage} total={totalCount} current={pageno} onChange={(page,size)=>{
  setPageNo(page)
}}/>
      </div>
    </div>
  );
};

export default ContractRequestsList;
