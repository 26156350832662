import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";

const initialState = {
  allContracts: [],
  selectedContract: {},
  filterResults: [],
  searchText: "",
  status: "",
  trip_Type: "",
  date: "",
  currentPage : 1,
  apiLoading: true,
};

export const getAllContractRequests = createAsyncThunk("contractrequest/getAllContractRequests", async (page,{rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.get(`/trip/contract-request?page=${page}`);
    // console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});

// export const getAllContractRequests = createAsyncThunk(
//   "contractrequest/getAllContractRequests",
//   async (page) => {
//     // console.log(initialState.currentPage)
//     const response = await axios({
//       method: "get",
//       headers: { Authorization: localStorage.getItem("authorization") },
//       url: `${process.env.REACT_APP_DEV_URL}/trip/contract-request?page=${page}`,
//     });

//     return response;
//   }
// );

export const getSingleTripDetails = createAsyncThunk(
  "contractrequest/getSingleTripDetails",
  async (id) => {
    const response = await axios({
      method: "get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/trip/contract-request/${id}/`,
    });

    return response;
  }
);

export const acceptTrip = createAsyncThunk(
  "contractrequest/accepttrip",
  async (id) => {
    const response = await axios({
      method: "get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/trip/contract-request/${id}/accept-trip/`,
    });

    return response;
  }
);

// export const rejectTrip = createAsyncThunk(
//   "contractrequests/rejectTrip",
//   async (data) => {
//     const response = await axios({
//       method: "patch",
//       headers: {
//         Authorization: localStorage.getItem("authorization"),
//         "Access-Control-Request-Methods": "PATCH",
//       },
//       url: `${process.env.REACT_APP_DEV_URL}/trip/contract-request/${data.id}/reject-trip/`,
//       data: { reason_for_rejection: data.reason },
//     });

//     return response;
//   }
// );

export const rejectTrip = createAsyncThunk("contractrequests/rejectTrip", async (data, {rejectWithValue, fulfillWithValue}) => {
  try {
    // console.log("im called")
    // console.log(axiosInstance.patch(`/trip/contract-request/${data.id}/reject-trip/`,{"reason_for_rejection": data.reason}))
    const response = await axiosInstance.patch(`/trip/contract-request/${data.id}/reject-trip/`,{"reason_for_rejection": data.reason});
    // console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});

export const searchTrip = createAsyncThunk(
  "contractrequests/searchTrip",
  async (data) => {
    // console.log(data)
    const response = await axios({
      //
      method: "get",
      url: `${process.env.REACT_APP_DEV_URL}/trip/contract-request/?page=${data.page+"&"}${data.searchText?"search=":""}${data.searchText?data.searchText + "&" : ""}${data.trip_Type?"trip_type=":""}${data.trip_Type?data.trip_Type+"&" : ""}${data.date?"created_at__date=":""}${data.date ? data.date :""}`,
      headers: {
        Authorization: localStorage.getItem("authorization"),
      },
    });
    // console.log(response);
    return response;
  }
);

const contractRequestSlice = createSlice({
  name: "contractrequest",
  initialState,
  reducers: {
    setSearch: (initialState, action) => {
      initialState.searchText = action.payload;
      // console.log(initialState.searchText)
    },
    setStatus: (initialState, action) => {
      initialState.status = action.payload;
      // console.log(initialState.status)
    },
    setTripType: (initialState, action) => {
      initialState.trip_Type = action.payload;
      // console.log(initialState.trip_Type)
    },
    setDate: (initialState, action) => {
      initialState.date = action.payload;
      // console.log(initialState.date)
    },
    nextPage: (initialState,action) =>{
      initialState.currentPage = action.payload
    },
    setAPIloading: (initialState,action) =>{
      initialState.apiLoading = action.payload
    },
    clearAllFilters: (initialState,action) =>{
      initialState.searchText = ""
      initialState.status = "";
      initialState.trip_Type = "";
      initialState.date = "";
    }
    // prevPage:(initialState,action) =>{
    //   initialState.currentPage = initialState.currentPage-1
    // },

  },
  extraReducers: (builder) => {
    builder.addCase(getAllContractRequests.fulfilled, (state, action) => {
      state.allContracts = action.payload.results;
      state.apiLoading = false;
    });
    builder.addCase(getSingleTripDetails.fulfilled, (state, action) => {
      state.selectedContract = action.payload.data;
    });
    builder.addCase(searchTrip.fulfilled, (state, action) => {
      state.filterResults = action.payload.data.results;
      state.apiLoading = false;
    //  state.date = "";
    //  state.trip_Type = "";
    //  state.status="";
    //  state.searchText="";
      // state.searchText=""
      // state.status=""
      // state.trip_Type=""
    });
    builder.addCase(rejectTrip.fulfilled,(state,action)=>{
      // console.log(action.payload)
    })
  },
});

export default contractRequestSlice;

export const contractRequestActions = contractRequestSlice.actions;
