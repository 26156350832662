import { ErrorMessage, Field, Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import TextError from "../TextError";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from "../../redux/rolesSlice";
import {
  createUser,
  editUser,
  getAllRoleDropDown,
} from "../../redux/userSlice";
import { message } from "antd";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const validationSchema = Yup.object({
  name_of_user: Yup.string()
    .required(" Name is required")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  user_name: Yup.string().required("username is required"),
  password: Yup.string()
    .required("password is required")
    .min(8, "minimum length should be 8 characters"),
  // .matches("password is not valid"),
  role: Yup.string().required("role is required"),
  // .matches("Role is not specified"),
  status: Yup.string().required("status is required"),
  // .matches("Status is not defined")
  email:Yup.string()
  .required("Email Address is required")
  .email("Please enter your valid Email Address"),
});

const NewUserCredentials = ({
  isModalOpen,
  setIsModalOpen,
  name,
  id,
  username,
  role,
  active,
  email,
  editing,
  editModal,
  setEditModal,
}) => {
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.roles);
  const userState = useSelector((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();

  // console.log(roleState.selectedUser)

  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const initialValues = {
    name_of_user: name ? name : "",
    user_name: username ? username : "",
    password: "",
    role: role ? role : "",
    status: active ? active : "",
    email: email ? email : ""
  };
  const onSubmit = (values, submitProps) => {
    let data = {};
    data.first_name = values.name_of_user;
    data.username = values.user_name;
    data.password = values.password;
    data.group = +values.role;
    data.is_active = values.status;
    data.email = values.email;

    if (data.password == "") {
      delete data.password;
    }
    // console.log(data)

    if (editing) {
      const editData = {
        id,
        data: data,
      };
      dispatch(editUser(editData)).then((res) => {
        // console.log(res);
        setIsModalOpen(false);
        success("User updated succesfully");
      });

      submitProps.resetForm();
    } else {
      dispatch(createUser(data)).then((res) => {
        if (res?.error?.message == "Rejected") {
          error(res.payload.errors.username);
          // setIsModalOpen(false);
          // submitProps.resetForm();
     
        }else{
 setIsModalOpen(false);
        success("User created successfully");
        submitProps.resetForm();
        }
       
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={editing ? null : validationSchema}
      // validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
      svalidateOnBlur={false}
    >
      {(formik) => {
        return (
          <Form autoComplete="off">
            {contextHolder}
            <hr />
            <div className="grid grid-cols-6 gap-6 py-5">
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="name_of_user"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Name of the User<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="name_of_user"
                  id="name_of_user"
                  placeholder="Name of the User"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="name_of_user" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Email<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="email" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="user_name"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  User Name<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="user_name"
                  id="user_name"
                  placeholder="Enter user name"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="user_name" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="password"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Password<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="password" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="role"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  User role
                </label>
                <Field
                  as="select"
                  name="role"
                  id="role"
                  className={`${
                    formik.values.role !== ""
                      ? "driveronboarding-input"
                      : "font-medium"
                  } w-full px-5 py-2.5 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg`}
                >
                  <option value="" disabled className="font-bold">
                    Select User Role
                  </option>
                  {/* {console.log()} */}
                  {userState.allRolesDropDown.map((rol) => (
                    <option className="driveronboarding-input" value={rol.id}>
                      {rol.name}
                    </option>
                  ))}
                  {/* <option value="Admin">Admin</option> */}
                </Field>
                <ErrorMessage name="roles" component={TextError} />
              </div>

              <div className="col-span-3 sm:col-span-6 ">
                <label
                  htmlFor="status"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Status
                </label>
                <Field
                  as="select"
                  name="status"
                  id="status"
                  className={`${
                    formik.values.status !== ""
                      ? "driveronboarding-input"
                      : "font-medium"
                  } w-full bg-bginputfield px-5 py-2.5 my-2  border-none font-medium text-xl text-form-input leading-6 rounded-lg`}
                >
                  <option value="" disabled>
                    Select status
                  </option>
                  <option className="driveronboarding-input" value="true">
                    Active
                  </option>
                  <option className="driveronboarding-input" value="false">
                    Inactive
                  </option>
                </Field>
                <ErrorMessage name="status" component={TextError} />
              </div>
            </div>
            <div className="col-span-6 sm:col-span-6">
              <div className="flex gap-3 items-center justify-center flex-wrap mt-4">
                <button
                  type="button"
                  className="w-[200px]  lg:ml-5 my-1 text-primary hover:bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-[200px]  lg:ml-5 my-1 text-primary hover:bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                >
                  {editing?"Update":"Add"}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewUserCredentials;
