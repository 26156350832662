import React, { useState,useEffect } from "react";
import arrowDown from "../../assets/images/arrow-down.svg";
import shape from "../../assets/images/Shape.svg";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSingleContractModification,acceptContractModification,
  rejectContractModification,
  cancellationReqAccept, } from "../../redux/contractModificationSlice";
import ModalLayout from "../../common/ModalLayout";
import { message } from "antd";
// import { contractModificationActions,getFilteredModifications } from "../redux/contractModificationSlice";

const ContractRequests = ({
  id_,
  title,
  tripType,
  id,
  customerName,
  customerNumber,
  customerEmail,
  TotalPrice,
  cost, 
  creditPoint,
  date,
  time,
  modification_status,
  user_id,
  reason_cancellation,
  reason_driver_change,
  end_time,
  end_date
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector(state=>state.auth)
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [acceptCancellation, setAcceptCancellation] = useState(false);
  const [acceptDriverChange, setAcceptDriverChange] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  const [editAcess,setEditAcess] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();

  // const [dropdownVisible, setDropdownVisible] = useState(false);
  const showOrderDetails = (id) => {
    dispatch(getSingleContractModification(id)).then((res)=>{
      navigate(`contract-request/${id}`);
    })
   
  };

  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  useEffect(()=>{
    // console.log(authState.acessMenus)
    for(let i =0 ; i <authState.acessMenus.length;i++){
  
      if(authState.acessMenus[i].menu == "contract_modification"){
      //  console.log(authState.acessMenus[i].menu == "contract")
      //  console.log(Object.keys(authState.acessMenus[i]?.actions))
     for(let j = 0; j < Object.keys(authState.acessMenus[i]?.actions).length ; j++){
         if(authState.acessMenus[i].actions.change){
          // console.log(true)
         setEditAcess(true)
         }
     }
    
      }
    }
  },[])
  const checkTripDateAlreadyStarted = (date)=>{
    const datesArray = date.split("/")
    const currentDate = new Date();
    const targetDate = new Date(datesArray[2], datesArray[1]- 1, datesArray[0]); 
    if(currentDate>targetDate){
      return true
    }else{
      return false
    }
  }
  const items = [
    {
      label: (
        <p
        
       onClick={()=>{
    if(checkTripDateAlreadyStarted(date) && (modification_status != "Cancel" && modification_status != "Driver Change")){
      error("Trip start date has been already passed")
    }else if(checkTripDateAlreadyStarted(end_date) && (modification_status == "Cancel" || modification_status == "Driver Change")){
      error("Trip end date has been already passed")
    }else{
      if(editAcess){
        if (
          modification_status == "Renew" ||
          modification_status == "Reschedule"
        ) {
          dispatch(
            acceptContractModification(
              id_
            )
          ).then((res) => {
            setAcceptModal(true);
          });
        } else if (
          modification_status == "Cancel"
        ) {
          setAcceptCancellation(true);
        } else if (
          modification_status == "Driver Change"
        ) {
          setAcceptDriverChange(true);
        }

       }
    }
        
        }}
          className= "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold"
       
        >
          Accept
        </p>
      ),
      key: "0",
    },
    {
      label: (
        <p
          className= "w-full py-[.5rem] text-xs  	 rounded-none pb-1 font-bold"
    
        onClick={() => editAcess && setRejectModal(true)}
  
        >
          Reject
        </p>
      ),
      key: "1",
    },
  ];
  return (
    <div className="flex justify-between   bg-bgcolor rounded-3xl mb-8 gap-12 p-6 ">
      {contextHolder}
      <div className=" flex flex-col justify-between text-secondary w-[100%] lg:w-[70%] ">
        <div className="flex justify-between w-full gap-5 ">
          <div className="px-2 text-orange-500	w-[200px]">
            <p className=" text-md font-bold ">{title} </p>
            <p className="text-xs font-medium text-zinc-400	 text-black">
              {tripType}
            </p>
            <p className="text-xs font-medium text-zinc-400	 text-black">
              Trip Id: {id}
            </p>
          </div>
          <div className="px-2 w-[200px]">
            <p className="mb-1 text-sm font-bold ">{customerName}</p>
            <p className="text-xs font-medium text-zinc-400	">
              {customerNumber}
            </p>
            <p className="text-xs font-medium text-zinc-400	">{customerEmail}</p>
          </div>
          <div className="px-2 w-[200px]">
            <p className="mb-1 text-sm font-bold ">
              Total price : {TotalPrice}{" "}
            </p>
            <p className="text-xs font-medium text-zinc-400	">cost: {cost}</p>
            <p className="text-xs font-medium text-zinc-400	">
              Credit points added : {creditPoint}
            </p>
          </div>
          <div className="px-2 w-[200px]">
            <p className="mb-1 text-sm font-bold ">{title == "Reschedule" && "Reschedule date and time" || title == "Cancel" && "Reason for cancellation" || title == "Renew" && "Renewed date and time" || title == "Driver Change" && "Reason for driver change"}</p>
            <p className="text-xs font-medium text-zinc-400	">  {title == "Renew" && date || title == "Reschedule" &&  date || title == "Cancel" && reason_cancellation || title =="Driver Change" && reason_driver_change} </p>
            <p className="text-xs font-medium text-zinc-400	">{(title == "Renew" && time || title == "Reschedule" &&  time)}</p>
          </div>
          <ModalLayout
        title="Add credit points"
        width={750}
        isModalOpen={acceptCancellation}
        setIsModalOpen={setAcceptCancellation}
        className="flex justify-center "
      >
        <hr />
        <div className="h-[300px] pt-20 px-14">
          <div className="flex flex-col ">
            <p className=" text-lg">Amount to be refunded</p>

            <div className="flex flex-col items-center">
              <input
                value={refundAmount}
                type="number"
                onChange={(e) => setRefundAmount(e.target.value)}
                id="first_name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter amount"
                required
              />
              <button
                type="button"
                onClick={() => {
                  const data = {
                    credit_amount: refundAmount,
                    user_id:
                      user_id,
                    trip_id: id_,
                  };

                  if (refundAmount != "") {
                    dispatch(cancellationReqAccept(data));
                    setAcceptCancellation(false);
                  } else {
                    error("amount cannot be empty");
                  }
                
                }}
                className=" ml-10 mt-10 w-[300px] h-[30px] lg:ml-5 my-1 text-white bg-primary mt-5 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
      <ModalLayout
        // title="Driver on boarding"
        width={500}
        isModalOpen={acceptModal}
        setIsModalOpen={setAcceptModal}
        className="flex justify-center items-center"
      >
        <div className=" h-[200px] flex  items-center text-center justify-center">
          <p className="text-xl font-extrabold text-orange-500 ">
            Ride information has been shared <br /> to the drivers
          </p>
        </div>
      </ModalLayout>
      <ModalLayout
        // title="Driver on boarding"
        width={500}
        isModalOpen={acceptDriverChange}
        setIsModalOpen={setAcceptDriverChange}
        className="flex justify-center items-center"
      >
        <div className=" h-[200px] flex flex-col  items-center text-center justify-around">
          <p className="font-bold text-xl pl-2">Message send to the User</p>
          <button
            onClick={() => {
              dispatch(
                acceptContractModification(
                  id_
                )
              ).then((res) => {
                setAcceptDriverChange(false);
              });
            }}
            className=" ml-10 w-[250px] h-[30px] lg:ml-5 my-1 text-white bg-primary mt-5 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
          >
            send
          </button>
        </div>
      </ModalLayout>
      <ModalLayout
        // title="Reason for rejection"
        width={750}
        isModalOpen={rejectModal}
        setIsModalOpen={setRejectModal}
        className="flex justify-center items-center"
      >
        <div className="h-[420px] ">
          <h1 className="text-2xl font-bold py-3">Reason for rejection</h1>
          <hr />
          <div className="py-7">
            <p className="pl-6 font-bold text-lg">Reason for rejection</p>
            {/* <input
                  type="text"
                  className="w-[95%] px-5 py-20 my-2 bg-bginputfield border-none font-medium text-sm  leading-6 rounded-lg outline-none"
                  placeholder="Enter reason"
                /> */}
            <div className="flex flex-col items-center">
              <textarea
                onChange={(e) => setRejectReason(e.target.value)}
                value={rejectReason}
                required
                rows="10"
                cols="90"
                placeholder="Enter reason"
                className="mt-3 bg-bginputfield border-none font-medium text-md  leading-6 rounded-lg outline-none px-5 pt-4"></textarea>
              <button
                type="button"
                onClick={() => {
                  const datas = {
                    id:id_,
                    reason: rejectReason,
                  };
                  // console.log(datas)
                  if(rejectReason != ""){
                    dispatch(rejectContractModification(datas)).then(() => {
                      setRejectModal(false);
                    });
                  }else{
                    error("reject reason can't be empty")
                  }

                
                }}
                className=" ml-10 w-[250px] h-[30px] lg:ml-5 my-1 text-white bg-primary mt-5 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
          <div className="px-2">
            <Dropdown
              menu={{
                items,
              }}
            >
              <button
                type="button"
                className="  w-[150px] h-[35px] lg:ml-5 my-1 text-white bg-primary hover:bg-secondary  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                disabled={!editAcess}
                // onClick={showModal}
              >
                Actions
                {/* <img
              className="h-5 w-5 text-white" style={{position: "relative" ,top:'-18px',right:'-108px'}}
              src={arrowDown}
              alt=""
         
            /> */}
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    &nbsp;
                    <DownOutlined />
                  </Space>
                </a>
              </button>
            </Dropdown>
          </div>

          <div className="flex items-center ml-auto">
            <img
              className="mr-5 cursor-pointer"
              src={shape}
              alt=""
              onClick={() => {
                showOrderDetails(id_);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractRequests;
