import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Select, DatePicker } from "antd";
import PageHeader from "../../layout/PageHeader";
import driver_img from "../../assets/images/driver_img.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDriverContracts,
  getSingleDriver,
  driverActions,
  getAllDriverContractsFiltered,
} from "../../redux/driverSlice";
import fileDownload from "js-file-download";
import axios from "axios";
import DriverContracts from "../DriverManagement/DriverContracts";

import "./drivermanagement.scss";
import DriverHeaderWOS from "./DriverHeaderWOS";
import { tConvert } from "../../common/timeConverter";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const DriverDetails = ({ driverId, driver_ID }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const driverState = useSelector((state) => state.driver);

  useEffect(() => {
    dispatch(getSingleDriver(driverId)).then(() => {
      dispatch(getAllDriverContracts(driver_ID));
    });
  }, []);

  useEffect(() => {
    dispatch(driverActions.clearAllFilters());
    dispatch(driverActions.clearDriverContractFilters());
  }, []);

  const SubTitle = () => {
    // console.log(driverState.allDriverContracts)

    return (
      <div className="flex items-center my-2">
        <Link
          to="/admin/driver-management"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black"
        >
          Driver management
        </Link>
        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>
        <span className="leading-5 text-base font-medium text-header-title">
          Driver details
        </span>
      </div>
    );
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    const data = {
      trip_type: driverState.contractType,
      date: driverState.contractDate,
      id: driverState.currentDriver.driver_id,
    };

    // console.log(data)
    // if(driverState.contractDate != "" || driverState.contractType != ""){
    dispatch(getAllDriverContractsFiltered(data));
    // }
  }, [driverState.contractDate, driverState.contractType]);

  const mapData =
    driverState.contractDate != "" || driverState.contractType != ""
      ? driverState.filteredDriverContracts
      : driverState.allDriverContracts;
  // console.log(mapData)
  setTimeout(() => setLoader(false), 1200);
  return loader ? (
    <div className="flex justify-center items-center h-[100vh]">
      <Spin indicator={antIcon} />
    </div>
  ) : (
    <div className="m-10">
      <DriverHeaderWOS pageTtitle="Driver Management" SubTitle={SubTitle} />
      <div>
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
          <div className="flex gap-8  w-[70%]">
            <div
              style={{
                height: "180px",
                width: "180px",
                backgroundColor: "black",
                borderRadius: "20px",
              }}
            >
              <img
                style={{
                  height: "180px",
                  width: "180px",
                  objectFit: "contain",
                }}
                className="min-w-[7rem] max-w-[20rem] "
                src={
                  driverState.currentDriver.driver_photo
                    ? driverState.currentDriver.driver_photo
                    : driver_img
                }
                alt="driver"
              />
            </div>
            <div className=" flex flex-col justify-between text-secondary w-[100%] lg:w-[70%] ">
              <h3 className="text-lg font-bold mb-4">
                {driverState.currentDriver.driver_id}
              </h3>
              <h2 className="text-2xl font-bold -mt-5 mb-5">
                {driverState.currentDriver.drivername}{" "}
              </h2>
              <div className="flex justify-between w-full gap-5 ">
                <div>
                  <p className="mb-1 text-xs ">Contact number </p>
                  <h3 className="text-md font-bold">
                    {driverState.currentDriver.mobile_number}{" "}
                  </h3>
                </div>
                <div>
                  <p className="mb-1 text-xs ">Whatsapp number </p>
                  <h3 className="text-md font-bold">
                    {driverState.currentDriver.whatsapp_number}{" "}
                  </h3>
                </div>
                <div>
                  <p className="mb-1 text-xs ">Email ID </p>
                  <h3 className="text-md font-bold">
                    {driverState.currentDriver.email_address
                      ? driverState.currentDriver.email_address
                      : "N/A"}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p
              className={`px-10 py-1 text-sm ${
                driverState.currentDriver.status == "Active"
                  ? "bg-active"
                  : "bg-orange-300"
              } rounded-lg`}
            >
              {driverState.currentDriver.status}
            </p>
          </div>
        </div>

        <div className="p-6 bg-white rounded-3xl mb-8">
          <h2 className="text-lg font-bold text-secondary mb-4">
            More detaIls
          </h2>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Vehicle type
            </p>
            <p className="text-secondary text-md font-bold">
              {driverState.currentDriver.vehicle_type}
            </p>
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">Driver type</p>
            <p className="text-secondary text-lg font-bold">
              {driverState.currentDriver.driver_type}
            </p>
          </div>
          {/* <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Vehicle registration number
            </p>
            <p className="text-secondary text-lg font-bold">
              {driverState.currentDriver.registraion_number}
            </p>
          </div> */}
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Driver’s license
            </p>

            {driverState.currentDriver.driver_license ? (
              <a
                download="license"
                target="_self"
                href={driverState.currentDriver.driver_license}
                className="text-primary text-lg font-bold underline-offset-4 decoration-1 underline text-md"
              >
                {" "}
                {driverState.currentDriver.driver_license
                  ? "driver license." +
                    driverState.currentDriver.driver_license.split(".").pop()
                  : ""}
              </a>
            ) : (
              <p className="text-secondary text-md font-bold">N/A</p>
            )}
            {/* </Link> */}
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey">
            <p className="text-base font-semibold text-greyText">
              Motor vehicle license
            </p>

            {driverState.currentDriver.vehicle_license ? (
              <a
                href={driverState.currentDriver.vehicle_license}
                className="text-primary text-lg font-bold underline-offset-4 decoration-1 underline text-md"
                download
              >
                {" "}
                {driverState.currentDriver.vehicle_license
                  ? `vehicle license.${driverState.currentDriver.vehicle_license
                      .split(".")
                      .pop()}`
                  : ""}
              </a>
            ) : (
              <p className="text-secondary text-md font-bold">N/A</p>
            )}
            {/* </Link> */}
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Card holder name
            </p>
            <p className="text-secondary text-lg font-bold">
              {driverState.currentDriver.card_holder_name}
            </p>
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Bank account number
            </p>
            <p className="text-secondary text-lg font-bold">
              {driverState.currentDriver.bank_account_number}
            </p>
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              State/Region
            </p>
            <p className="text-secondary text-lg font-bold">
              {driverState.currentDriver.region?.name}
            </p>
          </div>
        </div>
      </div>
      {driverState.driverContractLoading ? (
        <div className="flex justify-center items-center h-[30vh]">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className=" bg-white rounded-3xl mb-8 pb-4">
          <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
            <div className="col-span-1">
              <p className="font-bold text-xl leading-6 text-header-title my-2 px-5 py-5">
                Contracts
              </p>
            </div>
            <div className="col-span-1 flex items-center md:justify-between justify-end  gap-3 border-0 mx-5">
              <Select
                // allowClear
                className="w-[200px]  my-1 font-bold border-none select1 bg-[#f265220d] rounded-lg"
                defaultValue="Type"
                onChange={(val) => {
                  dispatch(driverActions.setContractType(val));
                }}
                bordered={false}
                options={[
                  {
                    value: "",
                    label: "All",
                    className:
                      "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                  },
                  {
                    value: "Day%20Trip",
                    label: "Day trip",
                    className:
                      "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                  },
                  {
                    value: "Contract%20Trip",
                    label: "Contract trip",
                    className: "w-full py-[.5rem] text-xs font-bold",
                  },
                ]}
              />
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(str, val) => {
                  let newdate = val.split("/").reverse().join("-");
                  dispatch(driverActions.setContractDate(newdate));
                }}
              />
              {/* </div> */}
            </div>
            <div className="flex items-center justify-between flex-wrap"></div>
          </div>
          {/* { console.log(mapdata)} */}
          {/* {console.log(contractState.filterResults.length>0 ? "hello" : "jjj")} */}
          {/* {contractState.filterResults.length > 0 ? contractState.filterResults : contractState.allContracts} */}
          {mapData.map((trip, index) => {
            return (
              <DriverContracts
                id={trip.id}
                key={trip.trip_id}
                title={trip.trip_type}
                trip_id={trip.trip_id}
                startdate={trip.start_date}
                starttime={tConvert(trip.start_time)}
                pickuploc1={trip.pickup_point?.adress}
                // pickuploc2={trip.pickup_point.adress_type}
                dropoffloc1={trip.drop_of_point?.adress}
                // dropoffloc2={trip.drop_of_point.adress_type}
                name={`${trip.user_id?.first_name} ${trip.user_id?.last_name}`}
                email={trip.user_id?.email}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DriverDetails;
