import React from "react";
import notificationStatus from "../../assets/images/notification-status.svg";

const DashboardBox = ({ icon, value, title, bgcolor }) => {
  return (
    <div
      className="drop-shadow-md rounded-lg min-w-fit flex flex-col "
      style={{ background: bgcolor ? bgcolor : "white" }}
    >
      <div className="flex flex-col justify-center p-5  ">
        <div className="mb-3 h-7 w-7">
          <img src={icon} />
        </div>
        <p className="text-4xl font-bold ">{value}</p>
        <p className="font-medium pt-2 text-sm">{title}</p>
      </div>
    </div>
  );
};

export default DashboardBox;
