import React from "react";
import CustomerDetails from "../components/CustomerManagement/CustomerList";
import PageHeader from "../layout/PageHeader";
import CustomerPageHeader from "../components/CustomerManagement/CustomerPageHeader";
import { customerSliceActions } from "../redux/customerSlice";

const CustomerManagement = () => {
  return (
    <div className="m-10">
      <PageHeader pageTtitle="Customer Management" onChangeFunc={customerSliceActions.setSearch}/>
      <CustomerDetails />
    </div>
  );
};

export default CustomerManagement;
