import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";

const initialState = {
  allDrivers: [],
  currentDriver: {},
  loading: false,
  currentDriverId: "",
  currentDriver_ID:"",
  editing: false,
  modal: false,
  filteredDrivers: [],
  allDriverContracts : [],
  selectedContract:{},
  searchText:"",
  status:"",
  filteredDriverContracts:[],
  contractType:"",
  contractDate:"",
  addingDriverLoading : false,
  editDriverStatus:false,
  deleteDriverStatus:false,
  apiLoading: true,
  driverRegionList : [],
  driverContractLoading: true
};







// export const getAllDrivers = createAsyncThunk(
//   "driver/getAllDrivers",
//   async (page) => {
//     const response = await axios({
//       method: "get",
//       headers: { Authorization: localStorage.getItem("authorization") },
//       url: `${process.env.REACT_APP_DEV_URL}/driver/driver?page=${page}`,
//     });

//     return response;
//   }
// );

export const getAllDrivers = createAsyncThunk(  "driver/getAllDrivers", async (page, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.get(`/driver/driver?page=${page}`);
  //   console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});

// export const getSingleDriver = createAsyncThunk(
//   "driver/getSingleDriver",
//   async (id) => {
//     const response = await axios({
//       method: "get",
//       headers: { Authorization: localStorage.getItem("authorization") },
//       url: `${process.env.REACT_APP_DEV_URL}/driver/driver/${id}`,
//     });

//     return response;
//   }
// );


export const getSingleDriver = createAsyncThunk("driver/getSingleDriver", async (id, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.get(`/driver/driver/${id}`);
  //   console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});


export const createDriver = createAsyncThunk(
  "driver/createDriver",
  async (driverData, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "post",
        headers: { "Content-Type": "multipart/form-data" ,Authorization: localStorage.getItem("authorization")},
    
        url: `${process.env.REACT_APP_DEV_URL}/driver/driver/`,
        data: { ...driverData },
      });
      return response;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDriver = createAsyncThunk("driver/deleteDriver", async (id, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.delete(`/driver/driver/${id}`);
    // console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});

// export const deleteDriver = createAsyncThunk(
//   "driver/deleteDriver",
//   async (id) => {
//     const response = await axios({
//       method: "delete",
//       Authorization: localStorage.getItem("authorization"),
//       url: `${process.env.REACT_APP_DEV_URL}/driver/driver/${id}`,
//     });

//     return response;
//   }
// );




export const updateDriver = createAsyncThunk("driver/updateDriver", async (data, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.patch(`/driver/driver/${data.id}/`,{...data});
    // console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});

// export const driverStatus = createAsyncThunk(
//   "driver/driverStatus",
//   async (data) => {
//     const response = await axios({
//       method: "get",
//       url: `${process.env.REACT_APP_DEV_URL}/driver/driver/?status=${data}`,
//     });
//     return response;
//   }
// );


export const getAllFilteredDriver = createAsyncThunk("driver/getAllFilteredDriver",async(data)=>{
  const response = await axios({
    method: "get",
    headers: { Authorization: localStorage.getItem("authorization") },
    url: `${process.env.REACT_APP_DEV_URL}/driver/driver/?page=${data.page+"&"}${data.searchText? "search=": ""}${data.searchText?data.searchText+"&":""}${data.status?"status=":""}${data.status?data.status:""}`,
})
return response
})

export const getAllDriverContracts = createAsyncThunk("driver/getAllDriverContracts", async(id)=>{
  const response = await axios({
    method:"get",
    url:`${process.env.REACT_APP_DEV_URL}/trip/trip/?trip_driver__driver_id=${id}`,
    headers: { Authorization: localStorage.getItem("authorization") },
  });
  // console.log(response)
  return response
})

export const getSingleContract = createAsyncThunk("driver/getSingleContract",async(id)=>{
  const response = await axios({
    method:"get",
    url:`${process.env.REACT_APP_DEV_URL}/trip/trip/${id}/`,
    headers: { Authorization: localStorage.getItem("authorization") },
  })
  // console.log(response)
  return response;
})

export const getAllDriverContractsFiltered = createAsyncThunk("driver/getAllDriverContractsFiltered",async(data)=>{
  const response = await axios({
    method: "get",
    headers: { Authorization: localStorage.getItem("authorization") },
    url: `${process.env.REACT_APP_DEV_URL}/trip/trip/?trip_driver__driver_id=${data.id + "&"}${data.date? "created_at__date=": ""}${data.date?data.date+"&":""}${data.trip_type?"trip_type=":""}${data.trip_type?data.trip_type:""}`,
})
return response
})

export const getAllRegionsForDriver = createAsyncThunk("driver/getAllRegionsForDriver",async()=>{
  const response = await axios({
    method:"get",
    url:`${process.env.REACT_APP_DEV_URL}/adress/region/`,
    headers: { Authorization: localStorage.getItem("authorization") },
  })
  // console.log(response)
  return response;
})

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    setDriverId: (initialState, action) => {
      // console.log(initialState)
      initialState.currentDriverId = action.payload;
    },
    setDriver_ID:(initialState,action)=>{
      initialState.currentDriver_ID = action.payload;
    },
    setEditingFlag: (initialState, action) => {
      initialState.editing = action.payload;
    },
    setStatus: (initialState, action) => {
      initialState.status = action.payload;
    },
    setSearch: (initialState, action)=>{
      initialState.searchText = action.payload
    },
    setContractType:(initialState,action)=>{
      initialState.contractType = action.payload
    },
    setContractDate:(initialState,action)=>{
      initialState.contractDate = action.payload
    },
    setAddDriverLoading:(initialState,action)=>{
      initialState.addingDriverLoading = true
    },
    setAPILoading:(initialState,action)=>{
      initialState.apiLoading = action.payload
    },
    clearAllFilters:(initialState,action)=>{
      initialState.status = "";
      initialState.searchText = ""

    },
    clearDriverContractFilters:(initialState,action)=>{
      initialState.contractType= ""
      initialState.contractDate = ""
      initialState.allDriverContracts = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDrivers.fulfilled, (state, action) => {
      state.allDrivers = action.payload.results;
       state.apiLoading = false
    });
    builder.addCase(createDriver.fulfilled,(state,action)=>{
      state.addingDriverLoading = false
    });
    builder.addCase(getSingleDriver.fulfilled, (state, action) => {
      state.currentDriver = action.payload;
    });
    builder.addCase(deleteDriver.fulfilled, (state, action) => {
     state.deleteDriverStatus = !state.deleteDriverStatus
    });
    builder.addCase(updateDriver.fulfilled, (state, action) => {
      state.editing = false;
      state.editDriverStatus = !state.editDriverStatus
    });
    builder.addCase(getAllDriverContracts.fulfilled,(state,action)=>{
      state.allDriverContracts = action.payload.data.results
      state.driverContractLoading = false
    })
    builder.addCase(getSingleContract.fulfilled,(state,action)=>{
      state.selectedContract = action.payload.data
    })
    builder.addCase(getAllFilteredDriver.fulfilled,(state,action)=>{
        state.filteredDrivers = action.payload.data.results
        state.apiLoading = false
    })
    builder.addCase(getAllDriverContractsFiltered.fulfilled,(state,action)=>{
      state.filteredDriverContracts = action.payload.data.results
      state.driverContractLoading = false
    })
    builder.addCase(getAllRegionsForDriver.fulfilled,(state,action)=>{
      state.driverRegionList = action.payload.data
    })
    
  },
});

export default driverSlice;

export const driverActions = driverSlice.actions;
