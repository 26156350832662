import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";


const initialState ={
    allUsers: [],
    userCreatedFlag:false,
    searchText:"",
    status:"",
    filteredUserList:[],
    selectedUser:{},
    deleteFlag:false,
    editFlag:false,
    allRolesDropDown:[],
    apiLoading:true
}

// export const getAllUsers = createAsyncThunk("user/getAllUsers",async(page)=>{
//     const response = await axios({
//         method:"get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/subadmin/list-subadmin?page=${page}`,
//     })
//     return response;
// })

export const getAllUsers = createAsyncThunk("user/getAllUsers", async (page, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.get(`/subadmin/list-subadmin/?page=${page}`);
    //   console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

// export const createUser = createAsyncThunk("user/createUser",async(data)=>{
//     const response = await axios({
//         method:"post",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/subadmin/add-subadmin`,
//         data:{...data}
//     })
//     return response;
// })


export const createUser = createAsyncThunk("user/createUser", async (data, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.post(`/subadmin/add-subadmin/`,{...data});
      // console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });


export const filterUsersList = createAsyncThunk("user/filterUsersList",async(data)=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/subadmin/list-subadmin/?page=${data.page+"&"}${data.status?"status=":""}${data.status?data.status+"&":""}${data.searchText?"search=":""}${data.searchText?data.searchText:""}`,
       
    })

    return response
})


export const getSelectedUser = createAsyncThunk("user/getSelectedUser",async(id)=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/subadmin/get-subadmin/${id}/`,
       
    })
// console.log(response)
    return response;
})




export const editUser = createAsyncThunk("user/editUser", async (data, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.patch(`/subadmin/update-subadmin/${data.id}/`,{...data.data});
      // console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });


// export const deleteUser = createAsyncThunk("user/deleteUser",async(id)=>{
//     const response = await axios({
//         method:"delete",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/subadmin/delete-subadmin/${id}/`,
       
//     })
//     return response;
// })


export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "delete",
        headers: { Authorization: localStorage.getItem("authorization")},
    
        url: `${process.env.REACT_APP_DEV_URL}/subadmin/delete-subadmin/${id}/`,
        
      });
      return response;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error);
    }
  }
);


export const getAllRoleDropDown = createAsyncThunk("user/getAllRoleDropDown",async()=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/role/dropdown-role/`,
       
    })
    return response;
})


const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setSearch:(initialState,action)=>{
            // console.log(action.payload)
            initialState.searchText = action.payload
            // console.log(initialState.serachText)
        },
        setStatus:(initialState,action)=>{
            initialState.status = action.payload
        },
        setAPILoading:(initialState,action)=>{
          initialState.apiLoading = action.payload
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(getAllUsers.fulfilled,(state,action)=>{
            state.allUsers = action.payload.results
            state.apiLoading = false
        })
        builder.addCase(createUser.fulfilled,(state,action)=>{
            state.userCreatedFlag = !state.userCreatedFlag
        })
        builder.addCase(filterUsersList.fulfilled,(state,action)=>{
            state.filteredUserList = action.payload.data.results
            state.apiLoading = false
        })
        builder.addCase(getSelectedUser.fulfilled,(state,action)=>{
            state.selectedUser =  action.payload.data.data
            // console.log(action.payload.data.data)
        })
        builder.addCase(deleteUser.fulfilled,(state,action)=>{
            state.deleteFlag = !state.deleteFlag
        })
        builder.addCase(editUser.fulfilled,(state,action)=>{
            state.editFlag = !state.editFlag
        })
        builder.addCase(getAllRoleDropDown.fulfilled,(state,action)=>{
            // console.log(action.payload.data.data)
            state.allRolesDropDown = action.payload.data.data
        })
    }
})


export default userSlice;

export const userSliceActions = userSlice.actions