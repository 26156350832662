import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";

const initialState = {
    allCustomers : [],
    filteredCustomersList : [],
    searchText : "",
    status : "",
    selectedCustomer:{},
    allCustomerOrdersList:[],
    selectedCustomerOrder:{},
    filteredCustomerOrders:[],
    orderDate:"",
    orderTrip_Type:"",
    editCustomerStatus:false,
    apiLoading: true
}


// export const getAllCustomers = createAsyncThunk("customers/getAllCustomers",async(page)=>{
//     const response = await axios({
//         method: "get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/customer/user/?page=${page}`,
//     })

//     return response
// })


export const getAllCustomers = createAsyncThunk("customers/getAllCustomers", async (page, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.get(`/customer/user/?page=${page}`);
    //   console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

export const getAllFilteredCustomers  = createAsyncThunk("customers/getAllFilteredCustomers",async(data)=>{
    const response = await axios({
        method: "get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/customer/user/?page=${data.page+"&"}${data.searchText?"search=":""}${data.searchText?data.searchText+"&":""}${data.status?"is_active=":""}${data.status?data.status:""}`,
    })
    return response
})


export const getSelectedCustomer = createAsyncThunk("customers/getSelectedCustomer", async (id, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.get(`/customer/user/${id}`);
    //   console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

// export const getSelectedCustomer =  createAsyncThunk("customers/getSelectedCustomer",async(id)=>{
//     const response = await axios({
//         method: "get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/customer/user/${id}`,
//     })

//     return response
// })

//list orders

export const getAllOrdersofSelectedCustomer = createAsyncThunk("customer/getAllOrdersofSelectedCustomer",async(id)=>{
    const response = await axios({
        method: "get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/trip/trip/?user_id__id=${id}&trip_payment__status=Success`,
    })

    return response
})



//order filter

export const getAllOrdersofSelectedCustomerFiltered = createAsyncThunk("customer/getAllOrdersofSelectedCustomerFiltered",async(data)=>{
    const response = await axios({
        method: "get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/trip/trip/?user_id__id=${data.id + "&"}trip_payment__status=Success&${data.orderTrip_Type?"trip_type=":""}${data.orderTrip_Type?data.orderTrip_Type+"&" : ""}${data.orderDate?"created_at__date=":""}${data.orderDate ? data.orderDate :""}`,
    })
    // console.log(response)
    return response
})

export const getselectedSingleOrder = createAsyncThunk("customer/getselectedSingleOrder",async(id)=>{
    const response = await axios({
        method: "get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/trip/trip/${id}`,
    })

    return response
})

export const editCustomer = createAsyncThunk("customer/editCustomer", async (data, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.patch(`/customer/user/${data.id}/`,{"is_active":data.status});
    //   console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });



//selected list

const customerSlice = createSlice({
    name:"customer",
    initialState,
    reducers:{
        setSearch:(initialState,action)=>{
            initialState.searchText = action.payload
        },
        setStatus:(initialState,action)=>{
            initialState.status = action.payload
        },
        setOrderType:(initialState,action)=>{
            initialState.orderTrip_Type = action.payload
        },
        setDate:(initialState,action)=>{
            initialState.orderDate = action.payload
        },
        setAPILoading:(initialState,action)=>{
          initialState.apiLoading = action.payload
        },
        clearAllFilters:(initialState,action)=>{
          initialState.searchText = ""
          initialState.status = ""
          // initialState.orderTrip_Type = action.payload
          // initialState.orderDate = action.payload
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(getAllCustomers.fulfilled,(state,action)=>{
            state.allCustomers = action.payload.results
            state.apiLoading = false
        })
        builder.addCase(getAllFilteredCustomers.fulfilled,(state,action)=>{
            state.filteredCustomersList = action.payload.data.results
            state.apiLoading = false
        })
        builder.addCase(getSelectedCustomer.fulfilled,(state,action)=>{
            state.selectedCustomer = action.payload
            state.apiLoading = false
        })
        builder.addCase(getAllOrdersofSelectedCustomer.fulfilled,(state,action)=>{
            state.allCustomerOrdersList = action.payload.data.results
            state.apiLoading = false
        })
        builder.addCase(getAllOrdersofSelectedCustomerFiltered.fulfilled,(state,action)=>{
            state.filteredCustomerOrders = action.payload.data.results
            state.apiLoading = false
        })
        builder.addCase(getselectedSingleOrder.fulfilled,(state,action)=>{
            state.selectedCustomerOrder = action.payload.data
            state.apiLoading = false
        })
        builder.addCase(editCustomer.fulfilled,(state,action)=>{
          state.editCustomerStatus = !state.editCustomerStatus
          state.apiLoading = false
        })
    }
})


export default customerSlice;
export const customerSliceActions = customerSlice.actions