import React from "react";
import ContractList from "../components/ContractManagement/ContractList";
import PageHeader from "../layout/PageHeader";
import ContractManagementPageHeader from "../components/ContractManagement/ContractManagementHeader";
import { contractManagementActions } from "../redux/contractManagementSlice";
function ContractManagement() {
  return (
    <div className="m-10 w-[93%]">
      <PageHeader pageTtitle="Contract Management" onChangeFunc={contractManagementActions.setSearch} />
      <ContractList />
    </div>
  );
}

export default ContractManagement;
