import React from "react";

const CardLayout = ({ children }) => {
  return (
    <div className=" p-6 bg-white border-gray-200 rounded-[32px]  ">
      {children}
    </div>
  );
};

export default CardLayout;
