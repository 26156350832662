import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";

const initialState = {
  allContracts: [],
  drivers: [],
  filteredContracts: [],
  selectedContract: {},
  status: "",
  type: "",
  searchText: "",
  date: "",
  currentContractModal: {},
  allDriversContract: [],
  allDriversContractFiltered: [],
  tripProceedStatus: false,
  selectDriverStatus: false,
  payment_link_send: "",
  apiLoading: true,
  // editSelectedDriverStatus:false
  setpagenumberFlag:false
};

export const getAllContracts = createAsyncThunk(
  "contractmanagement/getAllContracts",
  async (page, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosInstance.get(`/trip/trip?page=${page}`);
      // console.log(response)
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getFilterResults = createAsyncThunk(
  "contractmanagement/getFilterResults",
  async (data) => {
    // console.log(data)
    const response = await axios({
      method: "get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/trip/trip/?page=${
        data.page + "&"
      }${data.searchText ? "search=" : ""}${
        data.searchText ? data.searchText + "&" : ""
      }${data.contractStatus ? "status=" : ""}${
        data.contractStatus ? data.contractStatus + "&" : ""
      }${data.trip_Type ? "trip_type=" : ""}${
        data.trip_Type ? data.trip_Type + "&" : ""
      }${data.date ? "created_at__date=" : ""}${data.date ? data.date + "&" : ""}${
        data.payment_link_send && data.payment_link_send == "done"
          ? "trip_payment__status="
          : ""
      }${
        data.payment_link_send && data.payment_link_send == "done"
          ? "Success"
          : ""
      }${
        data.payment_link_send && data.payment_link_send == "pending"
          ? "trip_payment__status__isnull="
          : ""
      }${
        data.payment_link_send && data.payment_link_send == "pending"
          ? "true"
          : ""
      }`,
    });
    // console.log(response)
    return response;
  }
);

// export const getSingleContractDetails = createAsyncThunk("contractmanagement/getSingleContractDetails",async(id)=>{
//   const response = await axios({
//     method:"get",
//     headers: { Authorization: localStorage.getItem("authorization") },
//     url:`${process.env.REACT_APP_DEV_URL}/trip/trip/${id}/`
//   })
// // console.log(response)
//   return response;
// })

export const getSingleContractDetails = createAsyncThunk(
  "contractmanagement/getSingleContractDetails",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosInstance.get(`/trip/trip/${id}/`);
      // console.log(response)
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAllDriversContract = createAsyncThunk(
  "contractmangement/getAllDriversContract",
  async () => {
    // console.log("im here")
    const response = await axios({
      method: "get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/driver/driver-dropdown/?status=Active`,
      // ${data.search || data.driver_type ?"?":""}${data.search?"search=":""}${data.search ? data.search+"&" : ""}${data.driver_type?"driver_type=":""}${data.driver_type ? data.driver_type : ""}
    });

    return response;
  }
);

export const getAllDriversContractFilter = createAsyncThunk(
  "contarctmanagement/getAllDriversContractFilter",
  async (data) => {
    const response = await axios({
      method: "get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${
        process.env.REACT_APP_DEV_URL
      }/driver/driver-dropdown/?status=Active&${data.search ? "search=" : ""}${
        data.search ? data.search + "&" : ""
      }${data.driver_type ? "driver_type=" : ""}${
        data.driver_type ? data.driver_type : ""
      }`,
    });

    return response;
  }
);

// export const assignDriver = createAsyncThunk("contractmanagement/assignDriver",async(data)=>{
//   const response = await axios({
//     method:"patch"
//   })

// })

export const assignDriver = createAsyncThunk(
  "contractmanagement/assignDriver",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/trip/trip/${data.id}/`, {
        trip_driver: data.trip_driver,
      });
      // console.log(response)
      return fulfillWithValue(response.data);
    } catch (error) {
      if (error) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const proceedTrip = createAsyncThunk(
  "contractmanagement/proceedTrip",
  async (id) => {
    const response = await axios({
      method: "get",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/trip/trip/${id}/proceed-trip/`,
    });

    return response;
  }
);

// export const pendingPayment = createAsyncThunk("contractmanagement/pendingPayment",async()=>{
//   const response = await axios({
//     method:"get",
//     headers: { Authorization: localStorage.getItem("authorization") },
//     url: `${process.env.REACT_APP_DEV_URL}/trip/trip/${id}/proceed-trip/?trip_payment__status__isnull=true`,

//   })
//   return response
// })

// export const donePayment = createAsyncThunk("contractmanagement/donePayment",async()=>{
//   const response = await axios({
//     method:"get",
//     headers: { Authorization: localStorage.getItem("authorization") },
//     url: `${process.env.REACT_APP_DEV_URL}/trip/trip/${id}/proceed-trip/?trip_payment__status=Success`,

//   })
//   return response
// })

const contractManagementSlice = createSlice({
  name: "contractmanagement",
  initialState,
  reducers: {
    setStatus: (initialState, action) => {
      initialState.status = action.payload;
      // console.log(initialState.status)
    },
    setType: (initialState, action) => {
      initialState.type = action.payload;
      // console.log(initialState.type)
    },
    setSearch: (initialState, action) => {
      initialState.searchText = action.payload;
      // console.log(action.payload + "hhh" +initialState.searchText)
    },
    setDate: (initialState, action) => {
      initialState.date = action.payload;
    },
    setcurrentContractModal: (initialState, action) => {
      initialState.currentContractModal = action.payload;
    },
    setpaymentLink: (initialState, action) => {
      initialState.payment_link_send = action.payload;
    },
    setAPILoading: (initialState, action) => {
      initialState.apiLoading = action.payload;
    },
    setPageNumberFlagToggle:(initialState,action) =>{
      initialState.setpagenumberFlag = !initialState.setpagenumberFlag
    },
    clearAllFilters:(initialState,action) =>{
      initialState.status = "";
      initialState.type = "";
      initialState.searchText = "";
      initialState.date = "";
      initialState.payment_link_send = "";
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getAllContracts.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.allContracts = action.payload.results;
      state.apiLoading = false;
    });
    builder.addCase(getFilterResults.fulfilled, (state, action) => {
      state.filteredContracts = action.payload.data.results;
      state.apiLoading = false;
    });
    builder.addCase(getSingleContractDetails.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.selectedContract = action.payload;
    });
    builder.addCase(getAllDriversContract.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.allDriversContract = action.payload.data;
    });
    builder.addCase(getAllDriversContractFilter.fulfilled, (state, action) => {
      state.allDriversContractFiltered = action.payload.data;
    });
    builder.addCase(proceedTrip.fulfilled, (state, action) => {
      state.tripProceedStatus = !state.tripProceedStatus;
    });
    builder.addCase(assignDriver.fulfilled, (state, action) => {
      state.selectDriverStatus = !state.selectDriverStatus;
    });
    // builder.addCase(pendingPayment.fulfilled,(state,action)=>{
    //   state.
    // })
  },
});

export default contractManagementSlice;

export const contractManagementActions = contractManagementSlice.actions;
