import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../DriverManagement/drivermanagement.scss";
import CardLayout from "../../common/CardLayout";
import { Select, DatePicker } from "antd";
import ModalLayout from "../../common/ModalLayout";
import viewicon from "../../assets/images/viewicon.svg";
import { Link, useNavigate } from "react-router-dom";
import NewUserCredentials from "../../forms/UserMangement/NewUserCredentials";
import { useDispatch, useSelector } from "react-redux";
import searchicon from "../../assets/images/searchicon.svg";
import editicon from "../../assets/images/editicon.svg";
import { Radio,message } from 'antd';
import {
  getAllContracts,
  contractManagementActions,
  getSingleContractDetails,
  getAllDriversContract,
  getAllDriversContractFilter,
  assignDriver,
  proceedTrip
} from "../../redux/contractManagementSlice";
import { getNotificationDashboard } from "../../redux/dashboardSlice";
// import { getAllDrivers } from "../../redux/driverSlice";
import { getFilterResults } from "../../redux/contractManagementSlice";
import contractImg from '../../assets/images/contractprop.png'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'; 
// import { contractManagementActions } from "../../redux/contractRequestsSlice";
import { clearAllContractMod, clearAllContractRequest, clearAllCustomer, clearAllDriver, clearAllReports, clearAllRoles, clearAllUsers } from "../../common/clearfunction";
// import { Typography } from "antd/es/typography/Typography";

const ContractList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );
  const authState = useSelector(state=>state.auth)
  const driverState = useSelector((state) => state.driver);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tripDetails,setTripDetails] = useState(false)
  const [showContractDayTrip,setShowContractDayTrip] = useState(false)
  const [showContractContractTrip,setshowContractContractTrip] = useState(false)
  const [selectDriverModal,setSelectDriverModal] = useState(false)
  const [driverGender,setDriverGender] = useState("")
  const [searchVal,setSearchVal] = useState("")
  const [selectDriverAssignContract,setSelectedDriverAssignContract] = useState("")
  const [editAcess,setEditAcess] = useState(false)
  const [isModalSendContractOpen,setIsModalSendContractOpen] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const [totalCount,setTotalCount] = useState(10)
  const [pageno,setPageNo] = useState(1)
  const [selectedDriverForEdit,setSelectedDriverForEdit] = useState("")
  // const [loader,setLoader] = useState(true)
  const showModal = () => {
    setIsModalOpen(true);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(()=>{
    // console.log(authState.acessMenus)
    // setTimeout(()=>setLoader(false),1000)
    for(let i =0 ; i <authState.acessMenus.length;i++){
  
      if(authState.acessMenus[i].menu == "contract_management"){
      //  console.log(authState.acessMenus[i].menu == "contract")
      //  console.log(Object.keys(authState.acessMenus[i]?.actions))
     for(let j = 0; j < Object.keys(authState.acessMenus[i]?.actions).length ; j++){
         if(authState.acessMenus[i].actions.change){
          // console.log(true)
         setEditAcess(true)
         }
     }
    
      }
    }
  },[])
  const success = (data) => {
    messageApi.open({
      type: "success",
      content: data,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  useEffect(() => {
    const data = {
      searchText: contractManagementState.searchText,
      contractStatus: contractManagementState.status,
      trip_Type: contractManagementState.type,
      date: contractManagementState.date,
      page : pageno,
      payment_link_send:contractManagementState.payment_link_send
    };
    // contractManagementState.searchText != "" || contractManagementState.status != "" || contractManagementState.type != "" || contractManagementState.date != ""
    dispatch(contractManagementActions.setAPILoading(true))  
    dispatch(getFilterResults(data)).then(res=>setTotalCount(res.payload.data.count));
      // setTimeout(()=>setLoader(false),800)
      // return()=>{
      //   dispatch(contractManagementActions.setDate(""))
      //   dispatch(contractManagementActions.setStatus(""))
      //   dispatch(contractManagementActions.setType(""))
      //   dispatch(contractManagementActions.setSearch(""))
      // }
    
   
   
  }, [
    contractManagementState.searchText,
    contractManagementState.status,
    contractManagementState.type,
    contractManagementState.date,
    contractManagementState.payment_link_send,
    pageno,
    contractManagementState.tripProceedStatus,
    contractManagementState.selectDriverStatus
  ]);

  // console.log(contractManagementState.allContracts)

  const checkTripDateAlreadyStarted = (date)=>{
    const datesArray = date.split("/")
    const currentDate = new Date();
    const targetDate = new Date(datesArray[2], datesArray[1]- 1, datesArray[0]); 
    if(currentDate>targetDate){
      return true
    }else{
      return false
    }
  }

  useEffect(() => {
    dispatch(contractManagementActions.setAPILoading(true))  
    // setTimeout(()=>setLoader(false),1000)
   clearAllContractRequest()
   clearAllContractMod()
   clearAllCustomer()
   clearAllDriver()
   clearAllUsers()
   clearAllRoles()
   clearAllReports()
   dispatch(getNotificationDashboard())
   dispatch(contractManagementActions.setAPILoading(true))  
    if(contractManagementState.searchText == "" && contractManagementState.status == "" && contractManagementState.type == "" && contractManagementState.date == "" &&  contractManagementState.payment_link_send == ""){
      dispatch(getAllContracts(pageno)).then(res=>(contractManagementState.searchText == "" && contractManagementState.status == "" && contractManagementState.type == "" && contractManagementState.date == "")&&setTotalCount(res.payload.count));
      // setTimeout(()=>setLoader(false),800)
    }
    dispatch(getAllDriversContract());
  }, [selectDriverModal,pageno,contractManagementState.tripProceedStatus]);

 

  useEffect(()=>{
    const datass = {
      search:searchVal,
      driver_type:driverGender
    }
    dispatch(getAllDriversContractFilter(datass))
  },[searchVal,driverGender])


  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])
  // useEffect(()=>{
  //   //filter api
  //   dispatch()
  // })

  const mapdata =
    contractManagementState.searchText == "" &&
    contractManagementState.status == "" &&
    contractManagementState.type == "" &&
    contractManagementState.date == "" &&
    contractManagementState.payment_link_send == ""
      ? contractManagementState.allContracts
      : contractManagementState.filteredContracts;

  const columns = [
    {
      title: "Contract/ \nTrip ID",
      dataIndex: "trip_id",
      key: "trip_id",
      render: (trip_id, record) => (
        <p style={{ fontSize: "12px" }}>{trip_id}</p>
      ),
    },
    {
      title: "Customer name",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id, record) => (
        <p style={{ fontSize: "12px" }}>
          {user_id.first_name} {user_id.last_name}
        </p>
      ),
    },
    {
      title: "Contact number/ \nWhatsapp number",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id, record) => (
        <p style={{ fontSize: "12px" }}>{user_id.mobile_number}</p>
      ),
    },
    {
      title: "Email ID",
      key: "user_id",
      dataIndex: "user_id",
      render: (user_id, record) => (
        <p style={{ fontSize: "12px" }}>{user_id.email}</p>
      ),
    },
    {
      title: "Contract/ \nJourney",
      key: "contract",
      dataIndex: "contract",
      render: (_, record) => {
        return {
          props: {
            style: { color: "#f26522",cursor:"pointer" },
          },

          children: (
          <p onClick={()=>{
            // console.log(record)
            // setCurrentModalDetails(record)
            dispatch(contractManagementActions.setcurrentContractModal(record))
              setTripDetails(true)
        
           }}> {record.trip_type == "Day Trip"
           ? "Trip details"
           : "Contract details"}</p>
          ),
        };
      },
    },
    {
      title: "Total\nprice",
      key: "total_amount",
      dataIndex: "total_amount",
      render: (total_amount, record) => (
        <p style={{ fontSize: "12px" }}>{total_amount}</p>
      ),
    },
    {
      title: "Contract\nproposition",
      key: "contractcopy",
      dataIndex: "contractcopy",
      render: (_, record) => {
        return {
          props: {
            style: { color: "#f26522",cursor:"pointer" },
          },

          children: (
            record.payment_link_send ?
            <p onClick={()=>{
              if(record.trip_type == "Day Trip"){
                dispatch(contractManagementActions.setcurrentContractModal(record))
(editAcess&&setShowContractDayTrip(true))
              }else{
                dispatch(contractManagementActions.setcurrentContractModal(record))
(editAcess&&setshowContractContractTrip(true))
              }
            }}>Show contract
        
            <br />
            proposition</p>:<button className="bg-[#f265220d] p-2 text-xs rounded-lg font-bold" onClick={()=>{
            if(checkTripDateAlreadyStarted(record.start_date)){
              error("Trip date has been passed already")
            }else{
              if(record.trip_type == "Day Trip"){
                dispatch(contractManagementActions.setcurrentContractModal(record))
(editAcess&&setShowContractDayTrip(true))
              }else{
                dispatch(contractManagementActions.setcurrentContractModal(record))
(editAcess&&setshowContractContractTrip(true))
              }
            }
            }}>
              Send contract
              <br />
              proposition
              </button>
          ),
        };
      },
    },
    {
      title: "Payment\nstatus",
      key: "trip_payment",
      dataIndex: "trip_payment",
      render: (trip_payment, record) => (
        <p style={{ fontSize: "12px" }}>{trip_payment? trip_payment.status:"Not Paid"}</p>
      ),
    },
    {
      title: "Trip status",
      key: "status",
      dataIndex: "status",
      render: (status, record) => (
        <p style={{ fontSize: "12px" }}>{status}</p>
      ),
    },
    {
      title: "Payment\nmode",
      key: "trip_payment",
      dataIndex: "trip_payment",
      render: (trip_payment, record) => (
        <p style={{ fontSize: "12px" }}>{trip_payment&& trip_payment.payment_mode?trip_payment.payment_mode:"- - - - - - -"}</p>
      ),
    },
    {
      title: "Driver\nassigned",
      key: "trip_driver",
      dataIndex: "trip_driver",
      render: (trip_driver, record) => (
        trip_driver == null ?   <button onClick={()=>{
          // console.log(record.payment_link_send)
        //   if(record.payment_link_send == false){
             
        //  return   error("please send contract proposition in order to assign driver")
        //   }
             if(record.status == "Ended" ){
              error("Trip has ended")
             }else{
              setSelectedDriverAssignContract(record.id)
              setSelectDriverModal(true)
             }
              // console.log(record)
              // || record.payment_link_send?false:true
             }} className="bg-[#f265220d] p-2 text-xs rounded-lg font-bold" disabled={!editAcess }>Select driver</button>: <p onClick={()=>{
              // console.log(record)
              // console.log(record.payment_link_send)
           
              dispatch(contractManagementActions.setcurrentContractModal(record))
              navigate(`contract-details/${record.id}/driverDetails`)
             }} className="text-orange-500 cursor-pointer">Driver details</p>
      
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="flex items-center ">
            <img
              src={viewicon}
              alt="View"
              className="mx-2 cursor-pointer"
              title="View"
              // onClick={() => navigate(`contract-details/${record.key}`)}
              onClick={()=>dispatch(getSingleContractDetails(record.id)).then(()=>{
                dispatch(contractManagementActions.setcurrentContractModal(record))
                navigate(`contract-details/${record.id}`)
                // console.log(contractManagementState.selectedContract)
              })
              
              }
            />

<img
              src={editicon}
              alt="Edit"
              className="mx-2 cursor-pointer"
              onClick={() => {
                // console.log(record)
                if(record.status == "Ended" ){
                  error("Trip has ended")
                 }else{
                  setSelectedDriverAssignContract(record.id)
                  setSelectedDriverForEdit(record.trip_driver?.id)
               (editAcess&&setSelectDriverModal(true))
                 }
                
              }}
              title="Edit"
            />
         
          </div>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      contractid: "12345",
      customername: "Brooklyn Simmons",
      contactnumber: "(303) 555-0105",
      contract: "Trip details",
      totalprice: "9 OMR",
      emailid: "name@gmail.com",
      // contractcopy: "Contract \n propositions",
      paymentstatus: "Pending",
      contractcopy: "contract ID:10111 " + "\n" + " Trip ID:10010",
      driverassigned: "Driver deatails",
      paymentmode: "-----",
    },
    {
      key: "2",
      contractid: "12345",
      customername: "Brooklyn Simmons",
      contactnumber: "(303) 555-0105",
      contract: "Trip details",
      totalprice: "9 OMR",
      emailid: "name@gmail.com",
      // contractcopy: "Contract \n propositions",
      paymentstatus: "Pending",
      contractcopy: "contract ID:10111\nTrip ID:10010",
      driverassigned: "Driver deatails",
      paymentmode: "-----",
    },
    {
      key: "3",
      contractid: "12345",
      customername: "Brooklyn Simmons",
      contactnumber: "(303) 555-0105",
      contract: "Trip details",
      totalprice: "9 OMR",
      emailid: "name@gmail.com",
      // contractcopy: "Contract \n propositions",
      paymentstatus: "Pending",
      contractcopy: "contract ID:10111 " + "\n" + " Trip ID:10010",
      driverassigned: "Driver deatails",
      paymentmode: "-----",
    },
  ];
  const handleChange = (value) => {};



  
  // console.log(contractManagementState.allDriversContract)
  const mapDataDriver = searchVal != "" || driverGender != "" ? contractManagementState.allDriversContractFiltered : contractManagementState.allDriversContract
  return (
    <CardLayout>
         {contextHolder}
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-2">
        <div className="col-span-1">
          <p className="font-bold text-lg leading-6 text-header-title my-2">
            Contract Management
          </p>
        </div>
        <div className="col-span-1 flex items-center md:justify-between justify-end  gap-3 border-0 bg-[">
          <Select
            // allowClear
            // suffixIcon={<DownOutlined />}
            className="w-[170px]  my-1 font-bold border-0 bg-[#f265220d] rounded-lg"
            defaultValue="All"
            bordered={false}
            onChange={(val) =>{
              // setLoader(true)
              setPageNo(1)
              dispatch(contractManagementActions.setAPILoading(true))  
              // console.log(val)
              dispatch(contractManagementActions.setpaymentLink(val))
            }}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "done",
                label: "Payment done",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },

              {
                value: "pending",
                label: "Payment pending",
                className: "w-full py-[.5rem] text-xs font-bold ",
              },
            ]}
          />
          <Select
            // allowClear
            className="w-[200px]  my-1 font-bold border-none bg-[#f265220d] rounded-lg"
            defaultValue="Type"
            bordered={false}
            onChange={(val) => {
              // setLoader(true)
              setPageNo(1)
              dispatch(contractManagementActions.setAPILoading(true))  
              dispatch(contractManagementActions.setType(val))}}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Day%20Trip",
                label: "Day trip",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Contract%20Trip",
                label: "Contract trip",
                className: "w-full py-[.5rem] text-xs font-bold",
              },
            ]}
          />
          <DatePicker format="DD/MM/YYYY" onChange={(str,val)=>{
            setPageNo(1)
             dispatch(contractManagementActions.setAPILoading(true))  
             let newdate = val.split("/").reverse().join("-");
            dispatch(contractManagementActions.setDate(newdate))}} />
          {/* </div> */}
        </div>
        <div className="flex items-center justify-between flex-wrap"></div>
      </div>

      {contractManagementState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:  <Table
        rowKey={record => record.id}
        columns={columns}
        dataSource={[...mapdata]}
        scroll={{ x: 900 }}
        style={{ whiteSpace: "pre" }}
        className="font-sm"
        pagination={{  pageSize:10 ,total:totalCount ,onChange:(page,current)=>{setPageNo(page)},showSizeChanger:false , current:pageno}}
         
       
      />}
    
 <ModalLayout
            // title="Driver on boarding"
            width={500}
            isModalOpen={isModalSendContractOpen}
            setIsModalOpen={setIsModalSendContractOpen}
            className="flex justify-center items-center"
          >
            <div className=" h-[200px] flex  items-center text-center justify-center">
              <p className="text-xl font-extrabold text-orange-500 ">
                Contrat proposition document <br /> has been shared
              </p>
            </div>
          </ModalLayout>

      <ModalLayout
      width={700}
      isModalOpen={tripDetails}
      setIsModalOpen={setTripDetails}
      className="flex justify-center items-center"
      style={{marginBottom:"100px"}}
      >
        {/* {console.log(contractManagementState.currentContractModal)} */}
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 ">
        <div className="flex gap-6  w-[100%] flex-col">
          <div className="flex flex-col item-center w-[100%] gap-4">
            <div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100">
              <div className="">
                <p className="text-xl font-extrabold text-black	">
                
                    {contractManagementState.currentContractModal.trip_type}
                </p>
                {contractManagementState.currentContractModal.trip_type ==
                  "Contract Trip" && (
                  <p className="text-sm ">
                  
                    Contract name :{" "}
                    {contractManagementState.currentContractModal.trip_type !== "Day Trip" &&
                      contractManagementState.currentContractModal.contract_name}
                  </p>
                )}
                <p className="text-sm ">Trip Id:{contractManagementState.currentContractModal.trip_id}</p>
              </div>
             
            </div>
            <div className="flex justify-between w-[100%]  font-bold text-sm border-b  border-solid pb-2 border-slate-100">
              <p className="text-zinc-400	">Journey date and time</p>
              <p>      {contractManagementState.currentContractModal.start_date} &nbsp; &nbsp;{" "}
                {contractManagementState.currentContractModal.start_time}</p>
            </div>
            {/* <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-2 border-slate-100">
              <p className="text-zinc-400	">Waiting time</p>
              <p>{contractManagementState.currentContractModal.waiting_time ? contractManagementState.currentContractModal.waiting_time + " hours" : "0" } </p>
            </div> */}
            <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-2 border-slate-100">
              <p className="text-zinc-400	">Pickup location</p>
              {/* {console.log(contractManagementState)} */}
              <p>{contractManagementState.currentContractModal.pickup_point?contractManagementState.currentContractModal.pickup_point.adress:""}</p>
            </div>
          {contractManagementState.currentContractModal.trip_type == "Contract Trip" &&   <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-2 border-slate-100">
              <p className="text-zinc-400	">Dropoff Location</p>
              <p> {contractManagementState.currentContractModal.drop_of_point?contractManagementState.currentContractModal.drop_of_point.adress:""}</p>
            </div>}
       

            <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-2 border-slate-100">
              <p className="text-zinc-400	text-sm ">Trip type</p>
             {contractManagementState.currentContractModal.trip_type == "Contract Trip" &&  <p> {(contractManagementState.currentContractModal.trip_type =="Contract Trip"&& contractManagementState.currentContractModal.same_as_pickup_point)? "Two way trip" : "One way trip"}</p>}
              {contractManagementState.currentContractModal.trip_type == "Day Trip" && <p>{contractManagementState.currentContractModal.trip_type == "Day Trip" && contractManagementState.currentContractModal.trip_type }</p>}
            </div>

        {/* {contractManagementState.currentContractModal.trip_type == "Contract Trip" &&     <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-2 border-slate-100">
              <p className="text-zinc-400	">Region</p>
              <p className=" font-bold text-black	">  {contractManagementState.currentContractModal.region?.name}
</p>
            </div>} */}

            <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-2 border-slate-100">
              <p className="text-zinc-400	">Driver</p>
              <p className=" font-bold text-black	">  {contractManagementState.currentContractModal.service_type}
</p>
            </div>
          
          </div>
          <div className="flex justify-between  bg-white rounded-3xl ">
          <div className="flex   w-[100%]">
            <div className="flex flex-col item-center w-[100%] gap-4">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">Price details</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-3 border-slate-100">
                <p className="text-zinc-400	">Cost</p>
                <p> {contractManagementState.currentContractModal.price}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-3 border-slate-100">
                <p className="text-zinc-400	">Credit points added</p>
                <p>{contractManagementState.currentContractModal.credit_point}</p>
              </div>

              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Total</p>
                <p>{contractManagementState.currentContractModal.total_amount}</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

      </ModalLayout>


<ModalLayout
 width={600}
 height={700}
 bodyStyle={{marginTop: '100px'}} 
 isModalOpen={showContractDayTrip}
 setIsModalOpen={setShowContractDayTrip}
 className="flex justify-center items-center"
 style={{marginBottom:"400px", marginTop:"400px"}}
>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
              Contract proposition
                </p>
             
               
              </div>
             
            </div>
      <div>
        <p className="text-red-500 text-lg font-bold">For Day trip</p>
        <p className="text-md font-bold">Cars and driver’s documentations: <br /></p>
        <p>
- The car model is not less than 7 years old from the current year. <br />
- Motor vehicle license card must be registered with the name of the <br />
driver <br />
- Vehicle driving license must be 3 years or more.<br />
- Drivers must have a vehicle operating card from the Sanad office or <br />
from the Ministry of Transport, Communications, and Information <br />
Technology.<br />
- The vehicle must be clean, in a decent condition, and has Otaxi <br />
stickers. The vehicle will be subject to inspection by the Otaxi team.<br />
- All the official documentation should be valid during the trip</p>
<p className="text-md font-bold">Driver’s behaviors:<br /></p>
<p>
- No phones are allowed while driving.<br />
- Smoking is not allowed during the trip with customers.<br />
- It’s not allowed for the driver to ask the customers private questions.<br />
- Driver must abide by the agreed time of going and return, and to be <br />
present ten minutes before the actual time of the trip.<br />
- It’s not allowed for the driver to change with another driver without <br />
getting approval from the administration of Otaxi.<br />
- In the event of any violations, the case will be referred to the legal <br />
affairs.</p>
<p className="text-md font-bold">Financial terms:<br /> </p>
<p>
  <p className="font-medium">Customer:<br /></p>

- Customers must make the full payment before the start of the trip.<br />
- There is a refund in the case of cancellations.<br />
<p className="font-medium">Driver:<br /></p>

- It’s not allowed to ask the customer for the payment.<br />
- Financial department will transfer the trip’s cost when the trip is <br />
finished.<br />
- Driver should have an account at Bank Muscat.<br />
- The Monthly Contract department is not responsible for the incorrect <br />
bank information.</p>
      </div>
      {/* {console.log((contractManagementState.currentContractModal.payment_link_send || parseInt(contractManagementState.currentContractModal.total_amount) == 0)? "no button" : "show button")} */}
      <div className="flex justify-center">
      {contractManagementState.currentContractModal.payment_link_send ?null:  <button
                  //  type="submit"
                  // type="submit"
                  onClick={()=>{
                    dispatch(proceedTrip(contractManagementState.currentContractModal.id)).then(()=>{
                      
                      setShowContractDayTrip(false)})
                      setIsModalSendContractOpen(true)
                      success("Contract proposition approved")
                      setTimeout(()=>{
                        setIsModalSendContractOpen(false)
                      },1800)
                  }}
                  className="w-[200px]  lg:ml-5 my-1 text-white bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                >
                  Send contract proposition
                </button>}
      </div>
</div>
</ModalLayout>



<ModalLayout
width={600}
 height={700}
 bodyStyle={{marginTop: '100px'}} 
  isModalOpen={showContractContractTrip}
 setIsModalOpen={setshowContractContractTrip}
 className="flex justify-center items-center"
 style={{marginBottom:"400px", marginTop:"400px"}}

>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
              Contract proposition
                </p>
             
               
              </div>
             
            </div>
      <div>
        <p className="text-red-500 text-lg font-bold">For Contract trip</p>
        <p className="text-md font-bold">Cars and driver’s documentations: <br /></p>
        <p>
- The car model is not less than 7 years old from the current year. <br />
- Motor vehicle license card must be registered with the name of the <br />
driver <br />
- Vehicle driving license must be 3 years or more.<br />
- Drivers must have a vehicle operating card from the Sanad office or <br />
from the Ministry of Transport, Communications, and Information <br />
Technology.<br />
- The vehicle must be clean, in a decent condition, and has Otaxi <br />
stickers. The vehicle will be subject to inspection by the Otaxi team.<br />
- All the official documentation should be valid during the trip</p>
<p className="text-md font-bold">Driver’s behaviors:<br /></p>
<p>
- No phones are allowed while driving.<br />
- Smoking is not allowed during the trip with customers.<br />
- It’s not allowed for the driver to ask the customers private questions.<br />
- Driver must abide by the agreed time of going and return, and to be <br />
present ten minutes before the actual time of the trip.<br />
- It’s not allowed for the driver to change with another driver without <br />
getting approval from the administration of Otaxi.<br />
- Driver must abide by the duration of the monthly contract, and it is not allowed for<br />
the driver to withdraw from the contract except in the emergencies and should<br />
get the approval from the Monthly Contracts administration.<br />
- In the event of any violations, the case will be referred to the legal <br />
affairs.</p>
<p className="text-md font-bold">Financial terms:<br /> </p>
<p>
  <p className="font-medium">Customer:<br /></p>

  - Customers must make the full payment before the first date of <br />
contract. <br />
- There is no refund in the case of cancellations if the customer <br />
consumed half the duration of the contract. <br />
- Weekends and official holidays are not included in the contract except <br />
at the request of the customer. <br />
-It’s not allowed to delay/ postpone days from the contract period to <br />
after that period. <br />
<p className="font-medium">Driver:<br /></p>

- It’s not allowed to ask the customer for the payment. <br />
- Financial department will transfer the contract's cost after the contract <br />
is over. <br />
- Driver should have an account at Bank Muscat. <br />
- The Monthly Contract department is not responsible for the incorrect <br />
bank information. <br />
</p>
      </div>
      <div className="flex justify-center">
      {contractManagementState.currentContractModal.payment_link_send ?null:  <button
                  //  type="submit"
                  // type="submit"
                  onClick={()=>{
                    dispatch(proceedTrip(contractManagementState.currentContractModal.id)).then(()=>{
                      setIsModalSendContractOpen(true)
                      setshowContractContractTrip(false)})
                      success("Contract proposition approved")
                      setTimeout(()=>{
                        setIsModalSendContractOpen(false)
                      },1800)
                  }}
                  className="w-[200px]  lg:ml-5 my-1 text-white bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                >
                  Send contract proposition
                </button>}
      </div>
</div>
</ModalLayout>

<ModalLayout
 width={600}
 isModalOpen={selectDriverModal}
 setIsModalOpen={setSelectDriverModal}
 className="flex justify-center items-center"
 style={{marginBottom:"100px"}}
>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100 flex-col">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
              Select Driver
                </p>
             
               
              </div>

          
             
            </div>
            <div className="flex justify-between items-center">
              <div className="relative w-56">
              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <img src={searchicon} alt="" />
              </div>
              <input
                type="text"
                id="input-group-1"
                className=" border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-14 p-3.5 h-[39px]"
                // onChange={(e) => debounce(dispatch(contractManagementActions.setSearch(e.target.value)),2000)}
                onChange={(e)=>setSearchVal(e.target.value)}
                // onChange=""
                placeholder="Search"
              />
            </div>
            <div>
            <Radio.Group onChange={(e)=>setDriverGender(e.target.value)} value={driverGender} className="mr-8">
            <Radio className="text-lg border-red" value="">All</Radio>
      <Radio className="text-lg border-red" value="Male">Male</Radio>
      <Radio className="text-lg" value="Female">Female</Radio>
     
    </Radio.Group>
            </div>

              </div>
              <div className="flex flex-col justify around overflow-y-scroll	h-[250px]">
                    {mapDataDriver?.map(driver=>(
 <div className="flex justify-between items-center py-3 border-b border-orange-300" key={driver.id}>
 <p className="font-bold">{driver.driver_id}&nbsp;{driver.drivername}</p>
 <button onClick={()=>{
  const data = {
    id: selectDriverAssignContract,
    trip_driver:driver.id
  }
  dispatch(assignDriver(data)).then((response)=>{
if(response.meta.requestStatus == "fulfilled"){
 setSelectDriverModal(false)
 success("Driver assigned succesfully")
}
  })
 }} className={`${ selectedDriverForEdit == driver.id ? "bg-green-500" : "bg-[#f265220d]"}  p-2 px-3 text-xs rounded-lg mr-2 font-bold`}>{ selectedDriverForEdit == driver.id ? "Selected Driver" : "Select Driver"}</button>
 {/* {driver.driver_id == selectDriverAssignContract} */}
 </div>
                    ))}

              
           
              </div>
</div>
</ModalLayout>

      {/* <ModalLayout
        title="Add User Credentials"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        S
      > */}
      {/* <DriverOnBoarding
          
        /> */}
      {/* <NewUserCredentials
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </ModalLayout> */}
    </CardLayout>
  );
};

export default ContractList;
