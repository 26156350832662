import React from "react";
import shape from "../../assets/images/Shape.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSingleTripDetails } from "../../redux/contractRequestsSlice";

const ContractRequestDetails = ({
  id,
  title,
  trip_id,
  startdate,
  starttime,
  pickuploc1,
  pickuploc2,
  dropoffloc1,
  dropoffloc2,
  name,
  email,
  number
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showOrderDetails = (id) => {
    dispatch(getSingleTripDetails(id)).then(() => {
      navigate(`trip-details/${id}`);
    });
  };
  return (
    <div className="flex justify-between   bg-bgcolor rounded-3xl mb-8 gap-12 py-5 px-2 mx-3">
      <div className=" flex flex-col justify-between text-secondary w-[90%] lg:w-[70%] ">
        <div className="flex justify-between w-full gap-5 	">
          <div className="px-3 text-orange-500	w-[150px]">
            <p className=" text-sm font-bold ">{title} </p>
            <p className="text-xs font-medium text-black font-bold text-gray-500	">
              Trip Id: {trip_id}
            </p>
          </div>
          <div className="px-3 w-[200px]">
            <p className="mb-1 text-xs font-bold mr-10">{name}</p>
            <p className="text-xs font-medium font-bold text-gray-500">
            {email}
            </p>
            <p className="text-xs font-medium font-bold text-gray-500">
            {number}
            </p>
          </div>
          <div className="px-3 w-[200px]">
            {/* {console.log(title)} */}
            <p className="mb-1 text-xs font-bold "> Starting date and time</p>
            <p className="text-xs font-medium font-bold text-gray-500">
              {startdate}
            </p>
            <p className="text-xs font-medium font-bold text-gray-500">
              {starttime}
            </p>
          </div>
          {/* {title=="Contract Trip"&& <div className="px-3 w-[200px]">
            <p className="mb-1 text-xs font-bold ">Contract end date and time</p>
            <p className="text-xs font-medium font-bold text-gray-500">
              {startdate}
            </p>
            <p className="text-xs font-medium font-bold text-gray-500">
              {starttime}
            </p>
          </div>} */}
          <div className="px-3 w-[200px]">
            <p className="mb-1 text-xs font-bold ">Pickup location </p>
            <p className="text-xs font-medium font-bold text-gray-500">
              {pickuploc1}
            </p>
            <p className="text-xs font-medium font-bold text-gray-500">
              {pickuploc2}
            </p>
          </div>
       {title == "Contract Trip" &&  <div className="px-3 w-[200px]">
            <p className="mb-1 text-xs font-bold ">Dropoff location </p>
            <p className="text-xs font-medium font-bold text-gray-500">
              {dropoffloc1}
            </p>
     
          </div>}

          <div className="flex items-center ml-auto">
            <img
              className="mr-5 cursor-pointer"
              src={shape}
              alt=""
              onClick={() => {
                showOrderDetails(id);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractRequestDetails;
