import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";


const initialState = {
    allRoles:[],
    selectedRole:{},
    filteredRoleList:[],
    status:"",
    searchText:"",
    editFlag: false,
    deleteFlag: false,
    apiLoading:true
    
}


// export const getAllRoles = createAsyncThunk("roles/getAllRoles",async(page)=>{
//     const response = await axios({
//         method:"get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//         url: `${process.env.REACT_APP_DEV_URL}/role/list-role?page=${page}`,
       
//     })

//     return response;
// })


export const getAllRoles = createAsyncThunk("roles/getAllRoles", async (page, {rejectWithValue, fulfillWithValue}) => {
    try {
      const response = await axiosInstance.get(`/role/list-role/?page=${page}`);
    //   console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

export const createUserRole = createAsyncThunk("roles/createUserRole",async(data)=>{
    const response = await axios({
        method:"post",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/role/add-role/`,
        data:{...data}
    })
    return response
})


export const filterUserList = createAsyncThunk("roles/filterUserList",async(data)=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/role/list-role/?page=${data.page+"&"}${data.status?"status=":""}${data.status?data.status+"&":""}${data.searchText?"search=":""}${data.searchText?data.searchText:""}`,
       
    })

    return response
})

export const deleteUserRole = createAsyncThunk("roles/deleteRole",async(id)=>{
    const response = await axios({
        method:"delete",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/role/delete-role/${id}/`,
       
    })
    return response
})


export const getSelectedRole = createAsyncThunk("roles/getSelctedRole",async(id)=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/role/get-role/${id}/`,
       
    })
// console.log(response)
    return response;
})

export const editUserRole = createAsyncThunk("roles/editUserRole", async(data)=>{
    const editData = data.edit
    const response = await axios({
        method:"put",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/role/update-role/${data.id}/`,
        data:{...editData},
    })
    return response;
})


const rolesSlice = createSlice({
    name:"roles",
    initialState,
    reducers:{
        setSearch:(initialState,action)=>{
          
            initialState.searchText = action.payload
        },
        setStatus:(initialState,action)=>{
            initialState.status = action.payload;
        },
        setAPILoading:(initialState,action)=>{
            initialState.apiLoading = action.payload
          }
    },
    extraReducers:(builder)=>{
        builder.addCase(getAllRoles.fulfilled,(state,action)=>{
            // console.log(action)
            state.allRoles = action.payload.results
            state.apiLoading = false
        })
        builder.addCase(filterUserList.fulfilled,(state,action)=>{
            state.filteredRoleList = action.payload.data.results
            state.apiLoading = false
        })
        builder.addCase(getSelectedRole.fulfilled,(state,action)=>{
            state.selectedRole = action.payload.data.data
            state.apiLoading = false
        })
        builder.addCase(editUserRole.fulfilled,(state,action)=>{
            state.editFlag = !state.editFlag
        })
        builder.addCase(deleteUserRole.fulfilled,(state,action)=>{
            state.deleteFlag = !state.deleteFlag
        })
    }
})


export default rolesSlice;

export const rolesActions = rolesSlice.actions;

