import React from "react";
import { Modal } from "antd";
const ModalLayout = ({
  children,
  title,
  isModalOpen,
  setIsModalOpen,
  handleOk,
  width,
  style,
  height,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer
      width={width ? width : 600}
      height={height ? height : 350}
      style={style}
      centered
      transitionName=""
    >
      {children}
    </Modal>
  );
};

export default ModalLayout;
