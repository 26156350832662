import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import storage from "redux-persist/lib/storage";
import axiosInstance from "./axiosInstance";

const initialState = {
  loggedIn: null,
  token: localStorage.getItem("authorization")
    ? localStorage.getItem("authorization")
    : "",
  userType:"",
  acessMenus:[],
  email:"",
  unique_token:localStorage.getItem("unique_token")?
  localStorage.getItem("unique_token") : "",
  contract_request_flag:false,
  contract_management_flag:false,
  contract_modification_flag:false,
  customer_flag:false,
  driver_flag:false,
  user_flag:false,
  report_flag:false,
  user_name:""

};


export const login = createAsyncThunk("auth/login", async (data, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.post(`/auth/admin-login/`,{...data});
    // console.log(response)
    if (response.status == 200) {
              const token = response.data.data.tokens.access_token;
              // const unique_token = response.data.data.tokens.unique_token;
              const refresh_token = response.data.data.tokens.refresh_token;
      
              localStorage.setItem("authorization", `Bearer ${token}`);
              // localStorage.setItem("unique_token", unique_token);
              localStorage.setItem("refresh_token", refresh_token);
      
            }
            // console.log(response.data)
    return fulfillWithValue(response)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});



export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (data,{rejectWithValue}) => {
  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_DEV_URL}/auth/forgot-password-admin/`,
      data: { email:data.email , redirect_url: data.redirect_url },
    });
// console.log(response)
return response;
  } catch (error) {
    if (!error.response) {
      throw Error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updatePassword = createAsyncThunk("auth/updatePassword", async (data, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.patch(`/auth/change-password/`,{...data});
    // console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (initialState, action) => {
      initialState.loggedIn = false;
      initialState.token = "";
      initialState.userType = ""
      initialState.acessMenus = ""
      initialState.user_name=""
      // Storage.removeItem("persist:root");
      storage.removeItem("persist:root");
      localStorage.removeItem("authorization");
      localStorage.removeItem("refresh_token")
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.loggedIn = true;
      state.userType = action.payload.data.data.user_type;
      state.user_name = action.payload.data.data.username;
      state.acessMenus = action.payload.data.data.menu_list

      state.acessMenus.length>0&&state.acessMenus?.map((menu)=>{
      

        if(menu.name == "Contract Requests"){
          state.contract_request_flag = true
       
         
        }
        if(menu.name == "Contract Management"){
          state.contract_management_flag = true
        }
         if(menu.name == "Contract Modification Requests"){
          state.contract_modification_flag = true
         }
         if(menu.name == "Customer Management"){
          state.customer_flag = true
         }
         if(menu.name == "Driver Management"){
          state.driver_flag = true
         } 
         if(menu.name == "User Management"){
          state.user_flag = true
         }
         if(menu.name == "Reports"){
          state.report_flag = true
         }
       
      }) 
      
    });
  },
});

export default authSlice;

export const authActions = authSlice.actions;
