import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./axiosInstance";


const initialState = {
    allContractModifications:[],
    allFilteredContractModifications:[],
    searchText:"",
    trip_Type:"",
    date:"",
    selectedModificationContract:{},
    acceptFlag:false,
    rejectFlag:false,
    cancellationReqStatus:false,
    apiLoading: true,
}

// export const getAllContractModifications = createAsyncThunk("contractmodification/getAllContractModification",async(page)=>{
//     const response = await axios({
//         method:"get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//       url: `${process.env.REACT_APP_DEV_URL}/trip/modification-request/?page=${page}`,
//     })
//     return response
// })


export const getAllContractModifications = createAsyncThunk("contractmodification/getAllContractModification", async (page, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.get(`/trip/modification-request/?page=${page}`);
    // console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});

export const getFilteredModifications = createAsyncThunk("contractmodification/getFilteredModification",async(data)=>{
    const response = await axios({
        method:"get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/trip/modification-request/?page=${data.page+"&"}${data.searchText?"search=":""}${data.searchText?data.searchText + "&" : ""}${data.trip_Type?"modification_status=":""}${data.trip_Type?data.trip_Type+"&" : ""}${data.date?"created_at__date=":""}${data.date ? data.date :""}`,
    })

    return response
})

// export const getSingleContractModification = createAsyncThunk("contractmodification/getSingleContractModification",async(id)=>{
//     const response = await axios({
//         method:"get",
//         headers: { Authorization: localStorage.getItem("authorization") },
//       url: `${process.env.REACT_APP_DEV_URL}/trip/modification-request/${id}`,
//     })
//     return response
// })


export const getSingleContractModification = createAsyncThunk("contractmodification/getSingleContractModification", async (id, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await axiosInstance.get(`/trip/modification-request/${id}`);
    // console.log(response)
    return fulfillWithValue(response.data)
  } catch (error) {
    if(error){
      return rejectWithValue(error.response.data);
    }
  }
});

export const acceptContractModification = createAsyncThunk(
    "contractmodification/acceptContractModification",
    async (id) => {
      const response = await axios({
        method: "get",
        headers: { Authorization: localStorage.getItem("authorization") },
        url: `${process.env.REACT_APP_DEV_URL}/trip/modification-request/${id}/accept-request/`,
      });
  
      return response;
    }
  );

  export const rejectContractModification = createAsyncThunk("contractmodification/rejectContractModification", async (data, {rejectWithValue, fulfillWithValue}) => {
    try {
    //   console.log("im called")
      // console.log(axiosInstance.patch(`/trip/contract-request/${data.id}/reject-trip/`,{"reason_for_rejection": data.reason}))
      const response = await axiosInstance.patch(`/trip/modification-request/${data.id}/reject-request/`,{"reason_for_rejection": data.reason});
      // console.log(response)
      return fulfillWithValue(response.data)
    } catch (error) {
      if(error){
        return rejectWithValue(error.response.data);
      }
    }
  });

  export const cancellationReqAccept = createAsyncThunk("contractnodification/cancellationReqAccept",async(data)=>{
    const response = await axios({
      method: "post",
      headers: { Authorization: localStorage.getItem("authorization") },
      url: `${process.env.REACT_APP_DEV_URL}/wallet/credit/`,
      data:{...data}
    });

    return response;
  })


const contractModificationSlice = createSlice({
    name:"contractmodification",
    initialState,
    reducers:{
        setSearch: (initialState, action) => {
            initialState.searchText = action.payload;
            // console.log(initialState.searchText)
          },
          setTripType: (initialState, action) => {
            initialState.trip_Type = action.payload;
            // console.log(initialState.trip_Type)
          },
          setDate: (initialState, action) => {
            initialState.date = action.payload;
            // console.log(initialState.date)
          },
          setAPIloading: (initialState,action) =>{
            initialState.apiLoading = action.payload
          },
          clearAllFilters :(initialState,action)=>{
            initialState.searchText = "";
            initialState.trip_Type = "";
            initialState.date = "";
          }
    },
    extraReducers:(builder)=>{
        builder.addCase(getAllContractModifications.fulfilled,(state,action)=>{
            state.allContractModifications = action.payload.results
            state.apiLoading = false
         
        })
        builder.addCase(getFilteredModifications.fulfilled,(state,action)=>{
            state.allFilteredContractModifications = action.payload.data.results
            state.apiLoading = false
        })
        builder.addCase(getSingleContractModification.fulfilled,(state,action)=>{
            state.selectedModificationContract = action.payload
        }) 
        builder.addCase(acceptContractModification.fulfilled,(state,action)=>{
              state.acceptFlag = !state.acceptFlag
        })
        builder.addCase(rejectContractModification.fulfilled,(state,action)=>{
           state.rejectFlag = !state.rejectFlag
                  })
        builder.addCase(cancellationReqAccept.fulfilled,(state,action)=>{
          state.cancellationReqStatus =!state.cancellationReqStatus
        })          
    }
})

export default contractModificationSlice;

export const contractModificationActions = contractModificationSlice.actions;